import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Input, FormGroup, Label } from 'reactstrap';
import Axios from 'axios';
import { withTheme } from '../../contexts/AppContext';
import Select from 'react-select';
// import _Select from '../../components/_Select';
import _Select from 'react-select';
import { toast } from 'react-toastify';
import { Icon } from 'react-icons-kit'
import { ic_arrow_back } from 'react-icons-kit/md/ic_arrow_back';
import axios from 'axios';
import { Spinner } from 'react-activity'

export const _ModalHeader = ({
    back,
    heading,
    onCloseModal,
    className
}) => {
    return <div className={'d-flex justify-content-between container-fluid border-bottom pb-2 ' + className}>
        <div className='row mt-1'>
            {back && <Icon icon={ic_arrow_back} size={30} onClick={back} className='mr-2' />}
            {heading && <h2>{heading}</h2>}
        </div>
        {
            onCloseModal && <button className='btn btn-danger' onClick={onCloseModal}>Close</button>
        }
    </div>
}

export default withTheme(props => {
    // console.log('PROPS ----', props.context.restdata);
    let header = { 'foodbots-header': props.context.token };
    const [state, setState] = useState({
        items: [],
        categories: [],
        loading: false,
        display: 'form',
        btnText: 'Next',
        slots: [],
    });
    const [data, setData] = useState({
        rest_id: props.context.selectedRestID,
        start_time: null,
        selectedTemplate: null,
        repeat_days: null,
        end_time: null,
        order_stop_time: null,
        maxOrders: null,
        type: 'single',
        selected_date : null,
    });
    const [limit, setLimit] = useState([]);

    console.log({
        // state,
        data
    })

    const daysOfTheWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ]

    // const sendLimitData = () => {
    //     setState({ ...state, loading: false });
    //     alert('Sending Order Limit Data'+JSON.stringify(limit))
    // }

    const addSlot = () => {
        console.log('Sending ----', data);
        let startTime = new Date();
        let endTime = new Date();
        let startSplit = data.start_time.split(":");
        let endSplit = data.end_time.split(":");
        console.log(startSplit, endSplit);
        startTime.setHours(parseInt(startSplit[0]))
        startTime.setMinutes(parseInt(startSplit[1]))
        startTime.setSeconds(0)
        
        endTime.setHours(parseInt(endSplit[0]))
        endTime.setMinutes(parseInt(endSplit[1]))
        endTime.setSeconds(0)

        let postdata = {
            start_time : startTime,
            end_time : endTime,
            order_stop_time : parseInt(data.order_stop_time),
            type : data.type,
            rest_id : props.context.selectedRestID,
            item_limits : limit,
            selected_date : data.selected_date,
            repeat_days : data.repeat_days ? data.repeat_days.map(x => x.value) : null,
            menu_template : data.selectedTemplate.template_id
        }
        console.log(postdata);
        if (data.start_time) {
            let header = { "foodbots-header": props.context.token };
            Axios(window.kumbaURL + '/deliveryslots/create', {
                method: 'POST',
                headers: header,
                data: postdata
            })
                .then(res => {
                    toast.success('Delivery slots added')
                    props.toggle()
                    // props.updateItems(res.data)
                    // sendLimitData()
                })
                .catch(err => {
                    setState({ ...state, loading: false })
                    console.log(err)
                })
        } else {
            toast.error('Name or Max Orders is empty')
        }
    }

    const handleLimitChage = (e, val) => {
        if (e.target.value) {
            setLimit({ ...limit, [val.item_id]: e.target.value })
            // setLimit([...limit, ...{ item_id: val.item_id, max_limit: e.target.value }])
            //limit.push({ item_id: val.item_id, max_limit: e.target.value })
        }
        console.log('LIMIT ---', limit)
    }

    const getRestSlotTemplates = () => {
        axios(window.kumbaURL + '/menutemplate/rest/' + props.context.restdata.restaurant_id, {
            headers: header
        })
            .then(res => {
                // console.log('RES ---', res);
                setState({
                    ...state,
                    slots: res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (state.display === 'slots' && state.slots.length === 0) {
            getRestSlotTemplates();
        }
    }, [state.display]);

    return (
        <div>
            {
                state.display === "options" ?
                    <div style={{
                        "max-height": "75vh",
                        "overflow": "scroll"
                    }}>
                        <_ModalHeader
                            heading='Options'
                            back={() => setState({ ...state, display: 'slots' })}
                            className='mb-3'
                        />
                        {
                            data.selectedTemplate.categories ?
                                <div key={"categories-slot-add"}>
                                    {/* <div className='p-2 mb-0 h2 text-center'>{val.label}</div> */}
                                    <div className='table-responsive'>
                                        <table className="table align-items-center">
                                            <thead className='thead-dark text-white'>
                                                <tr>
                                                    <th className="text-white sort w-50" scope="col">Name</th>
                                                    <th className="text-white sort" scope="col">Category</th>
                                                    <th className="text-white sort" scope="col">Order Limit</th>
                                                </tr>
                                            </thead>
                                            {data.selectedTemplate.categories.map((val, i) =>
                                                props.context.restdata.menu_items.filter(x => x.category_id === val.id).map((item, i) => {
                                                    // console.log('ITEMS ---', val)
                                                    return <tbody className="list">
                                                        <tr>
                                                            <td className='h1'>{item.name}</td>
                                                            <td className="budget">{props.context.categoryTable[item.category_id].name}</td>
                                                            <td><input
                                                                className='form-control form-control-alternative'
                                                                type='number'
                                                                placeholder='Order Limit'
                                                                onChange={e => handleLimitChage(e, item)}
                                                            /></td>
                                                        </tr>
                                                    </tbody>
                                                }))}
                                        </table>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            data.selectedTemplate.items ?
                                <div key={"items-slot-add"}>
                                    {/* <div className='p-2 mb-0 h2 text-center'>{val.label}</div> */}
                                    <div className='table-responsive'>
                                        <table className="table align-items-center">
                                            <thead className='thead-dark text-white'>
                                                <tr>
                                                    <th className="text-white sort w-50" scope="col">Name</th>
                                                    <th className="text-white sort" scope="col">Category</th>
                                                    <th className="text-white sort" scope="col">Order Limit</th>
                                                </tr>
                                            </thead>

                                            {data.selectedTemplate.items.map((val, i) =>
                                                props.context.restdata.menu_items.filter(x => x.item_id === val.item_id).map((item, i) => {
                                                    console.log('ITEMS ---', item)
                                                    return <tbody className="list" key={item.item_id}>
                                                        <tr>
                                                            <td className='h1'>{item.name}</td>
                                                            <td className="budget">{item.category_id}</td>
                                                            {/* <td className="budget">{props.context.categoryTable[item.category_id].name}</td> */}
                                                            <td><input
                                                                className='form-control form-control-alternative'
                                                                type='number'
                                                                placeholder='Order Limit'
                                                                onChange={e => handleLimitChage(e, item)}
                                                            /></td>
                                                        </tr>
                                                    </tbody>
                                                }))}
                                        </table>
                                    </div>
                                </div>
                                : null
                        }
                        <hr />
                        <button className='btn btn-default w-100' onClick={addSlot}>Submit</button>
                    </div> : null
            }
            {
                state.display === 'slots' ? <div>
                    <_ModalHeader
                        heading='Select Slot Template'
                        className='mb-3'
                        back={() => setState({ ...state, display: 'form' })}
                    />
                    {
                        state.slots.length === 0 ? <div className='text-center m-5'>
                            <Spinner />
                        </div> : null
                    }
                    {
                        state.slots.map(val => {
                            return <div
                                className={'card border mb-2 ' + `${data.selectedTemplate && data.selectedTemplate.template_id === val.template_id ? 'shadow border border-default' : null}`}
                                key={val.template_id}
                                onClick={() => {
                                    setData({ ...data, selectedTemplate: val })
                                }}
                            >
                                <div className='card-body text-center'>
                                    <h3 className={'m-0 h2' + `${data.selectedTemplate === val.template_id ? 'bg-default' : null}`}>{val.name}</h3>
                                    <p>{"Included Categories : " + (val.categories.map(x => x.name).join(","))}</p>
                                    <p>{"Included Items : " + (val.items.map(x => x.name).join(","))}</p>
                                </div>
                            </div>
                        })
                    }
                    <hr />
                    <button className='btn btn-default w-100' disabled={!data.selectedTemplate} onClick={() => setState({ ...state, display: 'options' })}>Next</button>
                </div> : null
            }
            {
                state.display === 'form' ? <div>
                    <_ModalHeader
                        heading='Delivery Slot Form'
                        className='mb-3'
                    />
                    <div className='d-flex justify-content-center border'>
                        <a href='#single' className={`border p-3 rounded  ${data.type === 'single' ? 'shadow bg-default text-white' : 'text-default'}`} onClick={() => {
                            setData({ ...data, type: 'single' })
                        }}>Single Time Slot</a>
                        <a href='#multiple' className={`border p-3 rounded ${data.type === 'multiple' ? 'shadow bg-default text-white' : 'text-default'}`} onClick={() => {
                            setData({ ...data, type: 'multiple' })
                        }}>Multiple Time Slot</a>
                    </div>
                    <hr className='mb-2 mt-2' />
                    <Row>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label for="example-text-input" className="form-control-label">Repeated Day(s)</label>
                                <_Select
                                    options={daysOfTheWeek.map((val, i) => ({ value: i, label: val }))}
                                    isMulti
                                    isDisabled={data.type === 'single'}
                                    onChange={e => {
                                        setData({ ...data, repeat_days: e })
                                    }}
                                    value={data.repeat_days}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label for="example-text-input" className="form-control-label">{data.type === "single" ? "Date" : "Repeat Until"}</label>
                                <Input
                                    placeholder={data.type === "single" ? "Date" : "Repeat Until"}
                                    label={data.type === "single" ? "Date" : "Repeat Until"}
                                    type='date'
                                    onChange={e => {
                                        setData({
                                            ...data,
                                            selected_date: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label for="example-text-input" className="form-control-label">Start Time</label>
                                <Input
                                    placeholder='Start Time'
                                    label='Start Time'
                                    type='time'
                                    onChange={e => {
                                        setData({
                                            ...data,
                                            start_time: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label for="example-text-input" className="form-control-label">End Time</label>
                                <Input
                                    placeholder='End time'
                                    label='End Time'
                                    type='time'
                                    onChange={e => {
                                        setData({
                                            ...data,
                                            end_time: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label for="example-text-input" className="form-control-label">Order Stop Time</label>
                                <Input
                                    placeholder='Order Stop Time'
                                    label='Order Stop Time'
                                    type='integer'
                                    onChange={e => {
                                        setData({
                                            ...data,
                                            order_stop_time: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </Row>
                    <button className='btn btn-default w-100' onClick={() => {
                        setState({ ...state, display: 'slots' })
                    }}>Next</button>
                </div> : null
            }
        </div >
    )
});
