import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { Icon } from "react-icons-kit";
import { connection } from "react-icons-kit/icomoon/connection";
import { ic_refresh } from "react-icons-kit/md/ic_refresh";

export default function OfflienPopup() {
  const [show, setShow] = useState(false);
  window.addEventListener("offline", () => {
    setShow(true);
  });
  return (
    <Modal isOpen={show} style={{ paddingTop: "30vh" }}>
      <div className="card">
        <div className="card-body text-center">
          <Icon icon={connection} size={60} />
          <h2>Seems Like You've Gone Offline</h2>
          <Button className="btn-default mt-4" onClick={() => window.location.reload()}>
            <Icon icon={ic_refresh} /> Reload
          </Button>
        </div>
      </div>
    </Modal>
  );
}
