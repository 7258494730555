
import React, { Suspense, useState } from 'react';
import { Button, ListGroupItem } from 'reactstrap';
import { withTheme } from '../../contexts/AppContext';
import axios from 'axios';
import { useEffect } from 'react';
// const EachOrder_orders = React.lazy(() => import('../../dashboardComponents/orders/EachOrder'));
import useInterval from '../../helpers/useInterval';


const NotificationsView = withTheme(({ context, setNotifCount }) => {

    const [notifiedOrders, setNotifiedOrders] = useState({});

    const getDineInPendingOrders = async () => {
		try {
			if (context.restdata) {
                let restIDs = context.subrestaurants && context.subrestaurants.length > 0 ? context.subrestaurants.map(eachrest => eachrest.restdata.restaurant_id) : [];
                restIDs.push(context.restdata.restaurant_id);
				let url =
					window.kumbaURL +
					// '/mainorders/restpending/' +
					'/mainorders/assistance';
				let header = { 'foodbots-header': context.token }
				let response = await axios({
					method: 'POST',
					url,
					headers: header,
                    data : {
                        rest_ids : restIDs
                    }
				});

				let pendingOrdersTable = response.data;
                let pendingOrders = Array.prototype.concat.apply(
					[],
					Object.values(pendingOrdersTable)
				)
                console.log({pendingOrdersTable})
				setNotifiedOrders(pendingOrdersTable);
                setNotifCount(pendingOrders.length);
				return Promise.resolve(pendingOrdersTable);
			} else {
				return Promise.resolve(null)
			}
		} catch (e) {
			return Promise.reject(e)
		}
	}

    useEffect(() => {
        getDineInPendingOrders();
    }, []);

    useInterval(() => {
        getDineInPendingOrders();
    }, 15000);

    let rests = [context.restdata].concat(context.subrestaurants && context.subrestaurants.length > 0 ? context.subrestaurants.map(eachsub => eachsub.restdata) : [])
    let notificationList = Object.keys(notifiedOrders) && Object.keys(notifiedOrders).length > 0 ? Object.keys(notifiedOrders).map(eachrest => {
        let rest_info = rests.filter(x => x.restaurant_id === eachrest).pop();
        return(
            <>
            <h3 style={{color : 'white'}}>{rest_info.name}</h3>
                {notifiedOrders[eachrest].map(eachorder => eachorder.order_status === "bill_required" ?
                    <ListGroupItem>
                        <h4>{"Bill requested on table : " + eachorder.table_name}</h4>
                    </ListGroupItem> : null
                )}
            </>
        )
    }) : <h4 style={{justifyContent : "center", textAlign : "center", color : 'white'}}>No Notifications</h4>

    return(
        <div>
            {notificationList}
        </div>
    )
});

export default NotificationsView;