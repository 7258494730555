import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Spinner, Modal, ModalBody, Button } from 'reactstrap';
import { Icon } from 'react-icons-kit'
import { ic_group, ic_phone, ic_chat_bubble, ic_check_box } from 'react-icons-kit/md'
import { withTheme } from '../../contexts/AppContext';
import $ from 'jquery'

import Axios from 'axios';

export default withTheme(({ val, context }) => {

    const [state, setState] = useState({
        askDelete: false,
        deleteLoading: false
    });

    const handleDelete = () => {
        setState({ ...state, deleteLoading: true })
        //console.log('deleting ---', val);
        let header = { "foodbots-header": context.token };
        Axios(window.kumbaURL + '/deliveryslots/delete/' + val.slot_id, {
            headers: header,
            method: 'DELETE'
        })
            .then(res => {
                //console.log('res', res)
                setState({ ...state, deleteLoading: false });
                $(`#${val.slot_id}`).fadeOut(1000)
            })
            .catch(err => {
                setState({ ...state, deleteLoading: false })
                console.log('error --', err)
            })
    };

    return (
        <div id={val.slot_id} className='col-sm-12 col-md-6 col-lg-4 mt-3'>
            <Card style={{ borderRadius: '10px' }} className='shadow-sm p-0'>
                {
                    state.askDelete ? null : <CardHeader className='p-2' style={{
                        backgroundColor: '#2E4358',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px'
                    }}>
                        <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='text-white'>
                                <b>{displayDate(val.start_time)}</b>
                            </div>
                            <div className='text-white'>
                                {/* <Icon icon={ic_group} />{' '} */}
                                <b>{"to"}</b>
                            </div>
                            <div className='text-white'>
                                {/* <Icon icon={ic_group} />{' '} */}
                                <b>{displayDate(val.end_time)}</b>
                            </div>
                        </div>
                        </div>
                    </CardHeader>
                }
                <CardBody className='p-2'>
                    <div>
                        {
                            state.askDelete ? <div className='text-center pt-3'>
                                <h4>Are you sure you want to delete?</h4>
                            </div> :
                                
                                    <div className='mt-2'>
                                        <br />
                                        <small>{"Max Orders : " + val.maxOrders}</small><br />
                                        <small>{"Selected Categories : " + (val.categories.map(i => i.name + ", "))}</small><br />
                                        <small>{"Selected Items : " + (val.items.map(i => i.name + ", "))}</small><br /><br />
                                        <small><b>{"Order ends at : " + displayDate(val.order_stop_time)}</b></small><br />
                                    </div>
                        }
                        <hr className='m-1' />
                        {
                            state.askDelete ? <div style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <button disabled={state.deleteLoading} onClick={handleDelete} className='btn btn-danger m-1 w-100'>
                                    {
                                        state.deleteLoading ? <Spinner size="sm" /> : 'Yes'
                                    }
                                </button>
                                {
                                    <button className='btn btn-success m-1 w-100'
                                        onClick={() => setState({
                                            ...state,
                                            askDelete: !state.askDelete
                                        })}
                                    >No</button>
                                }
                            </div> :
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <button onClick={() => setState({ ...state, askDelete: !state.askDelete })} className='btn btn-danger m-1 w-100'>
                                        {
                                            state.deleteLoading ? <Spinner size="sm" /> : 'Delete'
                                        }
                                    </button>
                                    {/* {
                                        <button className='btn btn-success m-1 w-100'
                                            onClick={() => setState({
                                                ...state,
                                                showTables: !state.showTables
                                            })}
                                        >Edit</button>
                                    } */}
                                </div>
                        }

                    </div>
                </CardBody>
            </Card>
        </div>
    )
});


const displayDate = (date) => {
    // console.log(date);
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    //let time = dateObj.getTime();

    var hours = dateObj.getHours();
    var min = dateObj.getMinutes();
    var sec = dateObj.getSeconds();

    // console.log(hours, min, dateObj.toLocaleDateString())

    let hour = hours > 12 ? hours - 12 : hours;
    let ampm = hours >= 12 ? "pm" : "am";

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    sec = (sec < 10 ? "0" : "") + sec;

    let time = hour + ":" + min + ampm;

    return day + "/" + month + " " + time;
}
