import React from 'react'
import { NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { withTheme } from '../../contexts/AppContext'

export default withTheme(({
    to,
    title,
    icon,
    context
}) => {
    return (
        <NavItem className='nav-item active p-3 pl-5' onClick={() => {
            context.setContextState({
                sideNav: !context.sideNav
            })
        }}>
            <Link to={to} className='row'>
                <i className='mr-3'>
                    <Icon style={{ color: 'rgb(255 255 255 / 86%)' }} size={25} icon={icon} />
                </i>
                <h2 style={{ color: 'rgb(255 255 255 / 86%)'}}>{title}</h2>
                </Link>
        </NavItem>
    )
})
