import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, Row } from 'reactstrap'
import _Select from 'react-select'
import { withTheme } from '../../../contexts/AppContext'
import axios from 'axios'
import EachTemplate from './EachTemplate';
import TemplateForm from './TemplateForm'



const TemplateModal = withTheme(({
    toggle,
    show,
    context
}) => {

    const [state, setState] = useState({
        loading: false,
        data: [],
        showAdd: false
    })

    const getAllRestTemplate = () => {
        setState({ ...state, loading: true })
        console.log('GETING TEMPLATES')
        let header = { 'foodbots-header': context.token };
        axios(window.kumbaURL + '/menutemplate/rest/' + context.selectedRestData.restdata.restaurant_id, {
            headers: header
        })
            .then(res => {
                console.log(res)
                setState({ ...state, data: res.data, loading: false })
            })
            .catch(err => {
                setState({ ...state, loading: false })
                console.log(err)
            })
    }

    useEffect(() => {
        getAllRestTemplate()
    }, [])

    return (
        <Modal isOpen={show} size='lg'>
            <div className=' p-2 d-flex justify-content-between'>
                <h1>Manage Templates</h1>
                <button className='btn btn-danger' onClick={toggle}>Close</button>
            </div>
            <ModalBody className='p-3'>
                <TemplateForm
                    updateList={(newItem) => setState({
                        ...state,
                        data: [...state.data, newItem]
                    })}
                    show={state.showAdd}
                    toggle={() => setState({ ...state, showAdd: false })}
                    
                />
                <div className='text-center'>
                    <button className='btn btn-success' onClick={() => setState({ ...state, showAdd: true })}>Add +</button>
                </div>
                <hr className='mt-3 mb-1'/>
                {
                    state.data.map((val, i) => {
                        return <EachTemplate
                            data={val}
                            key={i}
                            _setState={setState}
                            _state={state}
                            getAllRestTemplate={getAllRestTemplate}
                        />
                    })
                }
            </ModalBody>
        </Modal>
    )
})

export default TemplateModal;
