import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import SlotForm from './SlotForm';
import Axios from 'axios';
import { withTheme } from '../../contexts/AppContext';
import EachSlot from './EachSlot';
import Layout from '../../components/Layout/Layout'
import AppLoading from '../../components/AppLoading';
import TemplateModal from './SlotTemplate/TemplateModal';

const TimeSlot = withTheme(props => {
    const [state, setState] = useState({
        showAdd: false,
        loading: false,
        items: [],
        showTemplates: false
    });

    const getItems = async () => {
        // console.log('getting..')
        setState({ ...state, loading: true })
        Axios({
            method: "GET",
            url: window.kumbaURL + '/deliveryslots/all/' + props.context.selectedRestID,
            headers: { 'foodbots-header': props.context.token }
        })
            .then(res => {
                //console.log(res.data)
                setState({
                    ...state,
                    loading: false,
                    items: res.data
                })
            })
            .catch(err => {
                setState({ ...state, loading: false })
                console.log(err)
            })

    }

    const updateItems = data => {
        setState({ items: [...state.items, data] })
    }

    const toggleTemplate = () => {
        setState({ ...state, showTemplates: !state.showTemplates })
    }

    useEffect(() => {
        getItems();
    }, [props.context.selectedRestID]);

    return (
        <Layout
            pageName='Delivery Slot'
            stickyComponent={
                <div className='d-flex justify-content-end'>
                    <button onClick={toggleTemplate} className='btn btn-default'>Manage Templates</button>
                    <TemplateModal show={state.showTemplates} toggle={toggleTemplate} />
                </div>
            }
        >
            <div className='container-fluid pb-5' style={{ marginBottom: '15%', marginTop: '5%' }}>
                <Modal isOpen={state.showAdd} size='lg' 
                >
                    <ModalBody className='pt-3 rounded'>
                        <SlotForm toggle={() => setState({
                            ...state,
                            showAdd: !state.showAdd
                        })} updateItems={updateItems} />
                        <div className='text-center mt-4'>
                            <button className='text-danger btn' onClick={() => setState({
                                ...state,
                                showAdd: !state.showAdd
                            })}>Close</button>
                        </div>
                    </ModalBody>
                </Modal>
                <button
                    className='btn text-white'
                    style={{
                        right: '20px',
                        position: 'fixed',
                        bottom: '20px',
                        zIndex: 10,
                        background: 'rgb(29, 53, 87)',
                        fontSize: '30px',
                        borderRadius: '100px'
                    }}
                    text="Add Time Slot"
                    onClick={() => setState({
                        ...state,
                        showAdd: !state.showAdd
                    })}
                >+</button>
                <hr />
                <div className='container'>
                    <div className='row justify-content-center'>
                        {
                            state.items.sort((a,b) => new Date(a.start_time) - new Date(b.start_time)).map((val, i) => <EachSlot val={val} key={i} />)
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
});

export default TimeSlot;
