import React, {useState} from 'react';
import { Row, Col, Label, Input } from 'reactstrap';
import Select from 'react-select';


const countryCodes = require('country-codes-list');

const PhoneField = ({phone, setPhone, phoneIG, setPhoneIG, readOnly}) => {

        const myCountryCodesObject = countryCodes.customList(('countryCallingCode'), '+{countryCallingCode}');
    // console.log(myCountryCodesObject);

    const myCountriesOptions = [];

    Object.keys(myCountryCodesObject).forEach(x => {
        myCountriesOptions.push({
            value : "+" + x,
            label : myCountryCodesObject[x]
        })
    });

    let phoneIGHTML = <Select
                            name="phoneIG"
                            value={myCountriesOptions
                                .filter(
                                    (rest) =>
                                        rest.value ===
                                        phoneIG
                                )
                                .pop()}
                            onChange={(val) => setPhoneIG(val.value)}
                            options={myCountriesOptions}
                            isDisabled={readOnly}
                        />

    return(
        <>
            <Label>Phone</Label>
            <Row noGutters>
                <Col xs={4}>
                    {phoneIGHTML}
                </Col>
                <Col xs={8}>
                    <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        pattern="[0-9]{10}"
                        placeholder="Phone Number"
                        value={phone || ''}
                        onChange={(e) => setPhone(e.target.value)}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>
        </>
    )
}

export default PhoneField;