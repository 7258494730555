import React, {useState, useEffect} from 'react';
import { Container, Form, Row, Col, Button, Label, FormGroup, ButtonToolbar, Input } from 'reactstrap';
import { FieldGroup } from '../../../helpers/FieldGroup';
import Global from '../../../Global';
import Select from 'react-select';
import Editor from "../../../components/Editor/Editor";
import ImageAndLogo from './ImageAndLogo';
import { next } from 'react-icons-kit/icomoon';
import PhoneField from '../../../helpers/PhoneField';

const countryCodes = require('country-codes-list');


const PlaceDetails = ({values, goNext, goPrev, add, edit}) => {
    let [name, setName] = useState(values.name || null);
     let [business_name, setBusinessName] = useState(values.business_name || null);
     let [phone, setPhone] = useState(values.phone ? values.phone.substr(-10) : null);
      let [cuisine, setCuisine] = useState(values.cuisine ? values.cuisine : null);
       let [type, setType] = useState(values.type || "foodndrinksprovider");
        let [description, setDescription] = useState(values.about_us || null);
        let [htmlDescription, setHTMLDescription] = useState(values.html_about_us || null);
        let [img, setImg] = useState(null);
        let [logo, setLogo] = useState(null);

        let [phoneIG, setPhoneIG] = useState(values.phone ? values.phone.slice(0, -10) : null);

    useEffect(() => {
        console.log(name, business_name, cuisine, phone, type, description);
    }, [name, business_name, cuisine, phone, type, description, htmlDescription]);

    useEffect(() => {
        setName(values.name || null)
        setBusinessName(values.business_name || null);
        setPhone(values.phone ? values.phone.substr(-10) : null);
        setCuisine(values.cuisine ? values.cuisine : null);
        setType(values.type || null);
        setDescription(values.about_us || null);
        setHTMLDescription(values.html_about_us || null);
        setPhoneIG(values.phone ? values.phone.slice(0, -10) : null);
    }, [values]);

    let doNext = () => {
        let values = {
            name : name,
            business_name : business_name,
            phone : phoneIG + phone,
            cuisine : cuisine,
            type : type,
            about_us : description,
            html_about_us : htmlDescription,
            img : img,
            logo : logo
        }
        goNext(values);
        
        // setName(null);
        // setBusinessName(null);
        // setPhone(null);
        // setCuisine(null);
        // setDescription(null);
        // setHTMLDescription(null);
        // setType(null);
    }

    return(
        <Container>
            <h5 style={{ textAlign: 'center' }}>
                        {"Step(1 of 4) : PLACE DETAILS"}
            </h5>
            <Form>
                <FieldGroup
                    readOnly={!add && !edit}
                    id="name"
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="Place's Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <FieldGroup
                    readOnly={!add && !edit}
                    id="business_name"
                    name="business_name"
                    type="text"
                    label="Business Name"
                    placeholder="Registered Name"
                    value={business_name}
                    onChange={(e) => setBusinessName(e.target.value)}
                />
                <Row>
                    <Col md={6} xs={12}>
                        <PhoneField phone={phone} setPhone={setPhone} phoneIG={phoneIG} setPhoneIG={setPhoneIG} readOnly={!add && !edit} />
                        
                    </Col>
                    <Col md={6} xs={12}>
                        <FormGroup>
                        <Label>{'Store Type :'}</Label>
                        <Select
                            name="restauranttype"
                            value={Global.restTypes
                                .filter(
                                    (rest) =>
                                        rest.value ===
                                        type
                                )
                                .pop()}
                            onChange={(val) => setType(val.value)}
                            options={Global.restTypes}
                            placeholder="Select One: "
                            isDisabled={!add && !edit}
                        />
                        </FormGroup>
                    </Col>
                </Row>
                <FieldGroup
                    id="cuisine"
                    name="cuisine"
                    type="text"
                    label="Tagline"
                    placeholder="Brewpub, Kitchen"
                    value={cuisine || ''}
                    onChange={(e) => setCuisine(e.target.value)}
                    readOnly={!add && !edit}
                />
                <FormGroup>
                <Label>About Us</Label>
                  {/* <p>{parent.state.description}</p> */}
                  <Editor
                    onChange={(data, text) => {
                        setHTMLDescription(data);
                        setDescription(text.replace("&nbsp;", " "))
                    }}
                    defaultValue={htmlDescription || description}
                    disabled={!add && !edit}
                  />
                  </FormGroup>
                  <ImageAndLogo values={values} setImg={setImg} setLogo={setLogo} isDisabled={!add && !edit} />
                  {add || edit ?
                        <ButtonToolbar>
                            <Button onClick={() => doNext()}>Next</Button>
                        </ButtonToolbar> : null
                  }
            </Form>
        </Container>
    )
}

export default PlaceDetails;