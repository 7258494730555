import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { withTheme } from '../../contexts/AppContext'
import moment from 'moment'
import { ListGroup, ListGroupItem, Modal } from 'reactstrap'
import { Icon } from 'react-icons-kit'
import { userOutline } from 'react-icons-kit/typicons/userOutline'

function Loyalty({ context }) {
	const [phoneNumber, setPhoneNumber] = useState(null)
	const [orderBill, setOrderBill] = useState(null)
	const [userDetails, setUserDetails] = useState(null)
	const [sortedTiers, setSortedTiers] = useState([])
	const [credits, setCredits] = useState([])
	const [totalCredits, setTotalCredits] = useState(0)
	const [maxUsableCredits, setMaxUsableCredit] = useState(0)
	const [userTier, setUserTier] = useState('Default')
	const { restdata } = context
	let loyaltyId = restdata?.loyalty.loyalty_id
	let to = moment().add(1, 'day').format('YYYY-MM-DD')
	let from = moment().subtract(90, 'day').format('YYYY-MM-DD')
	const [showEditModal, setShowEditModal] = useState(false)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')

	const getTotalCredits = async () => {
		try {
			let url = `${window.kumbaURL}/gettotalcredits/${phoneNumber}/${loyaltyId}`
			let header = { 'foodbots-header': context.token }
			let res = await axios(url, {
				headers: header,
			})
			console.log({ getTotalCredits: res.data })
			setTotalCredits(res.data.total_credits)

			return res.data
		} catch (err) {
			console.log(err)
			return err
		}
	}

	const getCredits = async () => {
		try {
			let url = `${window.kumbaURL}/getcreditsbyuser/${phoneNumber}/${loyaltyId}/${from}/${to}`
			let header = { 'foodbots-header': context.token }
			let res = await axios(url, {
				headers: header,
			})
			setCredits(res.data)
			console.log('CREDITS --- ', res.data)
		} catch (err) {
			console.log(err)
			return err
		}
	}

	const getUserDetails = async (e) => {
		e.preventDefault()
		console.log('PHONE SUBMIT')
		if (!phoneNumber || phoneNumber === '') {
			return
		}
		try {
			let url = `${window.kumbaURL}/getorderandbill/${phoneNumber}/${loyaltyId}/${from}/${to}`
			let header = { 'foodbots-header': context.token }
			let res = await axios(url, {
				headers: header,
			})
			console.log('USER DETAILS ----', { getOrderAndBill: res.data })
			setUserDetails(res.data)
			getTotalCredits()
			getCredits()
		} catch (err) {
			console.log(err)
			return Promise.reject(err)
		}
	}

	const getTiers = async () => {
		try {
			let url = `${window.kumbaURL}/getloyalty/${loyaltyId}`
			let header = { 'foodbots-header': context.token }
			let res = await axios(url, {
				headers: header,
			})

			let tierAmountSort = res.data.tier.sort(
				(a, b) => a.minimum_dollar_spent - b.minimum_dollar_spent
			)
			setSortedTiers(tierAmountSort)
			console.log('GET TIERS --', res)
		} catch (err) {
			console.log(err)
		}
	}

	const createLoyaltyOrder = async () => {
		try {
			let url = `${window.kumbaURL}/mainorders/addloyaltyorder`
			let header = { 'foodbots-header': context.token }
			let res = await axios({
				url: url,
				headers: header,
				method: 'POST',
				data: {
					user_id: phoneNumber,
					rest_id: context.selectedRestID,
					order_bill: orderBill,
				},
			})

			console.log('ADD Order --', res.data)
			return Promise.resolve(res.data)
		} catch (err) {
			console.log(err)
			return Promise.reject(err)
		}
	}

	const applyCredits = async (orderdata) => {
		try {
			let url = `${window.kumbaURL}/addusedcredit`
			let header = { 'foodbots-header': context.token }
			const res = await axios({
				url,
				headers: header,
				method: 'POST',
				data: {
					user: phoneNumber,
					loyalty: loyaltyId,
					order: orderdata.order_id,
					credit_amount: Math.round(maxUsableCredits * 100) / 100,
					credit_type: 'debit',
				},
			})
			console.log({ app: res.data })

			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	const addUserCredits = async (orderdata) => {
		try {
			let url = `${window.kumbaURL}/mainorders/addusercredits/${orderdata.order_id}`
			let header = { 'foodbots-header': context.token }
			const res = await axios({
				url,
				headers: header,
				method: 'PUT',
			})
			console.log({ app: res.data })
			return res.data
		} catch (err) {
			console.log(err)
		}
	}

	const submitBill = async (e) => {
		try {
			e.preventDefault()
			let orderdata = await createLoyaltyOrder()
			await applyCredits(orderdata)
			await addUserCredits(orderdata)
			return await getUserDetails(e)
		} catch (error) {
			return Promise.reject(error)
		}
	}

	useEffect(async () => {
		await getTiers()
	}, [])

	useEffect(() => {
		if (userDetails && sortedTiers) {
			let derivedTier = 'Default'
			sortedTiers.forEach((tier) => {
				if (
					userDetails.total_order_bills >= tier.minimum_dollar_spent &&
					userDetails.total_orders >= tier.minimum_order
				) {
					derivedTier = tier.name
				}
			})
			setUserTier(derivedTier)
		}
	}, [userDetails, sortedTiers])

	const displayDate = (date) => {
		// console.log(date);
		let dateObj = new Date(date)
		let month = dateObj.getMonth() + 1
		let day = dateObj.getDate()
		//let time = dateObj.getTime();

		var hours = dateObj.getHours()
		var min = dateObj.getMinutes()
		var sec = dateObj.getSeconds()

		// console.log(hours, min, dateObj.toLocaleDateString())

		let hour = hours > 12 ? hours - 12 : hours
		let ampm = hours >= 12 ? 'pm' : 'am'

		month = (month < 10 ? '0' : '') + month
		day = (day < 10 ? '0' : '') + day
		hour = (hour < 10 ? '0' : '') + hour
		min = (min < 10 ? '0' : '') + min
		sec = (sec < 10 ? '0' : '') + sec

		let time = hour + ':' + min + ampm

		return day + '/' + month + ' ' + time
	}

	const clearSearch = () => {
		setPhoneNumber('')
		setTotalCredits(0)
		setCredits([])
		setUserDetails(null)
	}

	return (
		<Layout>
			<Modal isOpen={showEditModal} style={{ marginTop: '30vh' }} size="lg">
				<div className="card">
					<div className="card-header p-2">
                        {/* You can change this condition 👇🏽 to the real deal */}
						<h2>{userDetails ? 'Edit' : 'Create'} User</h2>
					</div>
					<div className="card-body">
						<form className="row g-3">
							<div className="col-md-6">
								<label name="name" for="name" className="form-label">
									Name
								</label>
								<input
									type="text"
									className="form-control"
									id="name"
									defaultValue={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="col-md-6">
								<label for="email" className="form-label">
									Email
								</label>
								<input
									type="email"
									className="form-control"
									id="email"
									defaultValue={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>

							<div className="col-6 mt-5">
								<button type="submit" className="btn btn-primary w-100">
									Save
								</button>
							</div>
							<div className="col-6 mt-5">
								<button
									type="button"
									className="btn text-danger"
									onClick={() => setShowEditModal(false)}
								>
									Cancel
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
			<div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-sm-12 col-md-6 col-lg-6">
							<form
								className="card rounded p-3 mt-5 border border-gray"
								onSubmit={getUserDetails}
							>
								<div className="form-group">
									<label for="phoneNumberInput">Phone Number</label>
									<input
										onChange={(e) => setPhoneNumber(e.target.value)}
										type="text"
										className="form-control"
										id="phoneNumberInput"
										placeholder="Enter Phone No"
									/>
								</div>

								<button type="submit" className="btn btn-primary">
									Submit
								</button>
								<button onClick={clearSearch} className="btn">
									Clear Search
								</button>
							</form>
							{userDetails && (
								<div className="card mt-3">
									<div className="card-body">
										<div className="d-flex justify-content-between">
											<div className="d-flex align-content-center">
												<Icon icon={userOutline} size={40} />
												{/* If there is not user 👇🏽" */}
												{/* <div className='ml-2'>
                                                    <h2>User not found</h2>
                                                </div> */}
												{/* If user is found 👇🏽 */}
												<div className="ml-2">
													<h5 className="mb-0">Username</h5>
													<h6 className="text-muted">Phone Number</h6>
												</div>
											</div>
											<button
												className="btn btn-sm btn-success"
												onClick={() => setShowEditModal(true)}
											>
												Edit or Create User
											</button>
										</div>
									</div>
								</div>
							)}
							{userDetails && (
								<>
									<form
										className="card rounded p-3 mt-5 border border-gray"
										onSubmit={submitBill}
									>
										<div className="form-group">
											<label for="orderBill">Order Bill</label>
											<input
												onChange={(e) => {
													setOrderBill(e.target.value)
													setMaxUsableCredit(parseFloat(e.target.value) * 0.2)
												}}
												type="number"
												className="form-control"
												id="orderBill"
												placeholder="Order Bill"
											/>
											{/* <div className='text-center mb-2'>
                                        <h6>Maximum usable credits (20%) </h6>
                                    </div> */}
											<div className="d-flex">
												<h4>Maximum usable credit: </h4>
												<span>
													{maxUsableCredits > totalCredits
														? totalCredits
														: maxUsableCredits}
												</span>
											</div>
										</div>
										<button type="submit" className="btn btn-primary">
											Submit
										</button>
									</form>
									<div className="card p-2 mt-3">
										<div className="d-flex">
											<h4>Total Credits: </h4>
											<span>{totalCredits}</span>
										</div>
										<ListGroup>
											{credits
												.sort(
													(a, b) =>
														new Date(b.createdAt) - new Date(a.createdAt)
												)
												.map((credit, index) => (
													<ListGroupItem
														className="d-flex justify-content-around"
														style={{ borderBottomStyle: '1px solid' }}
													>
														<div>
															<p className="p-0 m-0">
																{credit.credit_type === 'expired'
																	? 'Expired'
																	: 'Order #' + credit.order.order_id}
															</p>
															<small>
																{displayDate(new Date(credit.createdAt))}
															</small>
														</div>
														<div
															style={{
																color:
																	credit.credit_type === 'credit'
																		? '#b5e7a0'
																		: '#ff6f69',
															}}
														>
															{credit.credit_type === 'credit' ? null : '-'}
															{`${window.rupeeSym}${credit.credit_amount}`}
														</div>
													</ListGroupItem>
												))}
										</ListGroup>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default withTheme(Loyalty)
