import React, { Component, useState } from 'react';
import { Input, FormGroup, InputGroup, InputGroupAddon, Label, FormFeedback } from 'reactstrap';

export const FieldGroup = function ({ id, label, help, color, ig, valid, invalid, style, styleInput, required, ...props }) {
	var validationState = valid || true
	return (
		<FormGroup controlId={id} validationState={validationState} style={style}>
			<Label>{label}</Label>
			{ig ?
				<InputGroup>
					<InputGroupAddon addonType="prepend">{ig}</InputGroupAddon>
					<Input className='form-control-alternative px-2'  {...props} valid={valid && validationState} invalid={invalid && !validationState} />
				</InputGroup> : <Input className='form-control-alternative' {...props} valid={valid && validationState} invalid={invalid && !validationState} required={required}/>
			}
			{help && <FormFeedback>{help}</FormFeedback>}

		</FormGroup>
	);
}