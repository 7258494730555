import React, {useState, useEffect} from 'react';
import { Container, Form, Row, Col, Button, Label, FormGroup, ButtonToolbar } from 'reactstrap';
import { FieldGroup } from '../../../helpers/FieldGroup';
import Global from '../../../Global';
import Select from 'react-select';
import Editor from "../../../components/Editor/Editor";

 const facebookIG = "https://www.facebook.com/";
    const instagramIG = "https://www.instagram.com/";
    const whatsappIG = "https://www.wa.me/91";

const SocialDetails = ({values, goNext, goPrev, add, edit}) => {
    let [facebook_handle, setFacebookHandle] = useState(values.facebook_url ? values.facebook_url.split(facebookIG)[1] : null);
     let [instagram_handle, setInstagramHandle] = useState(values.instagram_url ? values.instagram_url.split(instagramIG)[1] : null);
     let [restaurant_url, setRestaurantURL] = useState(values.restaurant_url || null);
      let [whatsapp_num, setWhatsappNum] = useState(values.whatsapp_url ? values.whatsapp_url.split(whatsappIG)[1] : null);

    let doNext = () => {
        let values = {
            facebook_url : facebook_handle ? facebookIG + facebook_handle : null,
            instagram_url : instagram_handle ? instagramIG + instagram_handle : null,
            restaurant_url : restaurant_url,
            whatsapp_url : whatsapp_num ? whatsappIG + whatsapp_num : null
        }
        goNext(values);
    }

    useEffect(() => {
        setFacebookHandle(values.facebook_url ? values.facebook_url.split(facebookIG)[1] : null);
        setInstagramHandle(values.instagram_url ? values.instagram_url.split(instagramIG)[1] : null);
        setRestaurantURL(values.restaurant_url || null);
        setWhatsappNum(values.whatsapp_url ? values.whatsapp_url.split(whatsappIG)[1] : null);
    }, [values]);

    return(
        <Container>
            <h5 style={{ textAlign: 'center' }}>
                {"Step(2 of 4) : SOCIAL DETAILS"}
            </h5>
            <Form>
                <FieldGroup
                    id="restaurant_url"
                    name="restaurant_url"
                    type="text"
                    label="Store URL"
                    placeholder="https://www.meetkumba.com"
                    value={restaurant_url}
                    onChange={(e) => setRestaurantURL(e.target.value)}
                    readOnly={!add && !edit}
                />
                <FieldGroup
                    id="facebook_url"
                    name="facebook_url"
                    type="text"
                    label="Facebook Page"
                    placeholder="meetkumba"
                    value={facebook_handle}
                    onChange={(e) => setFacebookHandle(e.target.value)}
                    ig={facebookIG}
                    readOnly={!add && !edit}
                />
                <FieldGroup
                    id="instagram_url"
                    name="instagram_url"
                    type="text"
                    label="Instagram URL"
                    placeholder="meetkumba"
                    value={instagram_handle}
                    onChange={(e) => setInstagramHandle(e.target.value)}
                    ig={instagramIG}
                    readOnly={!add && !edit}
                />
                {/* <FieldGroup
                    id="whatsapp_num"
                    name="whatsapp_num"
                    type="text"
                    label="Whatsapp Number"
                    placeholder="90xxxxx12"
                    value={whatsapp_num}
                    onChange={(e) => setWhatsappNum(e.target.value)}
                    ig={whatsappIG}
                    readOnly={!add && !edit}
                /> */}
                {add || edit ?
                    <ButtonToolbar>
                    <Button color="danger" onClick={() => goPrev()}>Back</Button>
                    <Button onClick={() => doNext()}>Next</Button>
                    </ButtonToolbar> : null
                }
            </Form>
        </Container>
    )
}

export default SocialDetails;