import Axios from 'axios'

import React, { useState, useEffect } from 'react'
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col,
	ListGroupItem,
	ListGroup,
} from 'reactstrap'
import { AppContext, withTheme } from '../../contexts/AppContext'
import ReactPaginate from 'react-paginate'
import './OrderHistory.css'
import EachOrder from '../../dashboardComponents/orders/EachOrder'
//import Button from '../../components/Button';
import { Dots } from 'react-activity'
import Layout from '../../components/Layout/Layout'
import EachOrderHistory from './EachOrderHistory'

export default withTheme((props) => {
	const [showSearch, setShowSearch] = useState(true)
	const [search, setSearch] = useState('')
	const [orders, setOrder] = useState({})
	const [pagination, setPagination] = useState({
		// data: new Array(1000).fill().map((value, index) => (({
		//     id: index,
		//     title: faker.lorem.words(5),
		//     body: faker.lorem.sentences(8)
		// }))),
		data: [],
		offset: 0,
		numberPerPage: 52,
		pageCount: 0,
		currentData: [],
	})

	const date = new Date()
	let strdate =
		date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()

	const [from, setFrom] = useState(strdate)
	const [to, setTo] = useState(strdate)
	const [loading, setLoading] = useState(true)

	var dater = new Date(orders.createdAt).toString()

	var formatteddate = dater.substring(0, dater.indexOf(' GMT'))
	var total =
		orders.order_bill +
		orders.order_bill * (0.01 * orders.tax_rate) +
		orders.service_fee -
		orders.discount

	const getOrderHistory = () => {
		setLoading(true)
		//console.log(props)
		props.context.setContextState({ pageName: 'Orders History' })
		//console.log('History Props -----', props.context.mainrestdata.restdata.restaurant_id)
		const restID = props.context.mainrestdata
			? props.context.mainrestdata.restdata.restaurant_id
			: null
		let header = { 'foodbots-header': props.context.token }
		let url =
			window.kumbaURL +
			'/mainorders/history/' +
			props.context.selectedRestID +
			'/' +
			from +
			'/' +
			to
		Axios({
			method: 'GET',
			headers: header,
			url: url,
		})
			.then((res) => {
				setPagination({
					...pagination,
					data: res.data.sort((a, b) => a.createdAt - b.createdAt),
				})
				setLoading(false)
				setPagination((prevState) => ({
					...prevState,
					pageCount: prevState.data.length / prevState.numberPerPage,
					currentData: prevState.data.slice(
						pagination.offset,
						pagination.offset + pagination.numberPerPage
					),
				}))
			})
			.catch((err) => {
				setLoading(false)
				// this.setState({
				//     hasError: true,
				//     isLoading: false
				// })
			})
	}

	useEffect(() => {
		getOrderHistory()
	}, [pagination.numberPerPage, pagination.offset])

	const handlePageClick = (event) => {
		const selected = event.selected
		const offset = selected * pagination.numberPerPage
		setPagination({ ...pagination, offset })
	}
	return (
		<Layout
			pageName="Order History"
			stickyComponent={
				<header className="card shadow p-2 w-100">
					<button
						onClick={() => setShowSearch(!showSearch)}
						className={`btn btn-sm btn-${
							showSearch ? 'danger' : 'success'
						} position-absolute`}
						style={{ right: 0, top: '-7px' }}
					>
						{showSearch ? `Hide` : 'Show'}
					</button>
					{showSearch && (
						<div className="row align-items-center">
							<div className="col-lg-5 col-sm-12 my-1">
								<span>From </span>
								<input
									className="form-control"
									defaultValue={from}
									type="date"
									onChange={(e) => setFrom(e.target.value)}
								/>
							</div>
							<div className="col-lg-5 col-sm-12 my-1">
								<span> To </span>
								<input
									className="form-control"
									defaultValue={to}
									type="date"
									onChange={(e) => setTo(e.target.value)}
								/>{' '}
							</div>
							<div className="col-lg-2 col-sm-12 my-1">
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => getOrderHistory()}
								>
									Get Orders
								</button>
							</div>
						</div>
					)}
				</header>
			}
		>
			{loading ? (
				<div className="text-center p-4" style={{ marginTop: '20rem' }}>
					<Dots size={30} />
				</div>
			) : (
				<div className="mt-14 w-100" style={{ marginTop: '10rem' }}>
					<div className="row justify-content-end mb-4">
						<div className="col-xl-4 col-sm-12">
							<input
								placeholder="Search table"
								type="search"
								onChange={(e) => setSearch(e.target.value)}
								className="form-control"
							/>
						</div>
					</div>
					<div className="row pb-5">
						{pagination.currentData && pagination.currentData.length === 0 ? (
							<div className="mt-14 w-100 text-center p-4">
								<h2 className="text-muted">No Data Found</h2>
							</div>
						) : null}
						{pagination.currentData &&
							pagination.currentData
								.sort(
									(a, b) =>
										new Date(b.createdAt).getTime() -
										new Date(a.createdAt).getTime()
								)
								.map((order, index) =>
									search === '' ||
									order?.table_id?.table_name
										.toLowerCase()
										.includes(search.toLowerCase()) ? (
										<div
											key={`each-order-history-${index}`}
											className="col-xl-3 col-sm-12 my-1"
										>
											<EachOrderHistory
												data={order}
												parent={{
													props,
													state: {
														orderdata: order,
													},
												}}
											/>
										</div>
									) : null
								)}
					</div>
					<div className="text-center w-100 bg-default text-white row justify-content-center align-items-center mt-5 mb-0">
						<ReactPaginate
							previousLabel={'previous'}
							nextLabel={'next'}
							breakLabel={'...'}
							pageCount={pagination.pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={handlePageClick}
							containerClassName={'pagination col-sm-12 my-1 col-md-10'}
							activeClassName={'active'}
						/>
					</div>
				</div>
			)}
		</Layout>
	)
})
