import sailsIOClient from 'sails.io.js';
//var sailsIOClient = require('sails.io.js');
import socketIOClient from 'socket.io-client';

//var io = sailsIOClient.sails.connect('https://api.foodbots.co');

var io = sailsIOClient(socketIOClient);

io.sails.url = process.env.REACT_APP_BACKEND_URL;
//io.sails.url"https://api.foodbots.net" = 'http://localhost:1447';

export default io;