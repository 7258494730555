import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// import {registerServiceWorker} from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import "./plugins/nucleo/css/nucleo.css";
// // import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// import "./css/argon-dashboard-react.css";
// import "./css/custom.css";
// import "./css/custom_2.css";

//window.kumbaURL = "https://api.foodbots.co";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray;
}

window.vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
window.convertedVapidKey = urlBase64ToUint8Array(window.vapidPublicKey);

window.kumbaURL = process.env.REACT_APP_BACKEND_URL;
window.rupeeSym =
    process.env.REACT_APP_COUNTRY === 'IN'
        ? '\u20B9'
        : process.env.REACT_APP_COUNTRY === 'UK'
        ? '\u00A3'
        : process.env.REACT_APP_COUNTRY === 'US'
        ? '$'
        : '\u20B9';

window.phoneCode = process.env.REACT_APP_PHONE_CODE || "+91";

window.googleCountriesList = process.env.REACT_APP_COUNTRY === "IN" ? ['in'] : 
                                 process.env.REACT_APP_COUNTRY === "UK" ? ['gb', 'be', 'ro'] :
                                 process.env.REACT_APP_COUNTRY === "US" ? ['us'] : ['in'];
window.server_roles = {
    chef : 4,
    bartender : 4,
  server: 1,
  waiter: 2,
  steward: 3,
  captain: 4,
  supervisor: 5,
  manager: 6,
  director: 7,
  general_manager: 8,
  owner: 9
};