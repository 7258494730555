import React, { Component } from 'react';
import io from '../socket-client';
import axios from 'axios';
import { toast } from 'react-toastify';

export const AppContext = React.createContext({
    restID: null,
    token: null,
    restdata: null,
    activated: null,
    loading: false,
    io: null,
    loadRestaurantDetails: () => { },
    login: () => { },
    logout: () => { },
});

export class AppContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.ordersList = [];
        this.masterCategoryTable = {};
        var restID = sessionStorage.getItem('restID');
        var token = sessionStorage.getItem('token');
        let selectedRestID = sessionStorage.getItem('selectedRestID');
        this.state = {
            restID: restID || null,
            isLoggedin: false,
            token: token || null,
            restdata: null,
            activated: null,
            loading: false,
            isRestDone: false,
            categoryItems: {},
            categories: [],
            categoryIDItems: {},
            categoriesList: [],
            pos_menu_types: [],
            categoryTable: {},
            menuTable:{},
            categoryChoices: [],
            itemChoices: {},
            itemTable: {},
            starredItems: [],
            io,

            selectedRestID: selectedRestID || null,
            selectedRestData: null,

            selectedAddress : null,
            delivery_charge : 0.00,

            customer_preferred_time : null,

            mainrestdata: null,

            staff: null,

            suborder: {},
            activeOrder: null,
            activeTable: null,
            ordersList: [],
            tables:{},

            reservations: [],
            waitList: [],
            waitListLoading: false,
            reservationsLoading: false,

            selectedStaff: null,
            staff_is_authorized: false,

            unconfirmedTotal: null,
            unconfirmedCount: null,
            unconfirmedTax: null,
            unconfirmedTaxes: null,
            unconfirmedCGST: null,
            unconfirmedSGST: null,
            unconfirmedVAT: null,
            unconfirmedServiceFee: null,
            unconfirmedFees: null,
            unconfirmedGrandTotal: null,
            unconfirmedPayTotal: null,
            unconfirmedTaxDetails: null,
            unconfirmedCreditsUsed: null,
            orderCredits: null,
            

            loadRestaurantDetails: this.loadRestaurantDetails.bind(this),
            getCategoryItems: this.getCategoryItems.bind(this),
            login: this.login.bind(this),
            logout: this.logout.bind(this),
            signup: this.signup.bind(this),
            signupRestaurant: this.signupRestaurant.bind(this),
            updateRestaurant: this.updateRestaurant.bind(this),
            addServer: this.addServer.bind(this),
            getServer: this.getServer.bind(this),

            setContextState: this.setContextState.bind(this),

            removeItemInOrder: this.removeItemInOrder.bind(this),
            updateItemInOrder: this.updateItemInOrder.bind(this),
            addOrders: this.addOrders.bind(this),
            saveOrder: this.saveOrder.bind(this),
            updateOrder: this.updateOrder.bind(this),
            deleteOrder: this.deleteOrder.bind(this),
            submitOrder: this.submitOrder.bind(this),
            clearOrder: this.clearOrder.bind(this),

            // initializeNotificationUI: this.initializeNotificationUI.bind(this),
            subscribePush: this.subscribePush.bind(this),
            unsubscribePush: this.unsubscribePush.bind(this),

            setLoading: this.setLoading.bind(this),
            unsetLoading: this.unsetLoading.bind(this),

            getPaymentUpdates: this.getPaymentUpdates.bind(this),
            removePaymentUpdate: this.removePaymentUpdate.bind(this),
            paymentUpdates: null,

            changeTableDetails: this.changeTableDetails.bind(this),

            selectRestaurant: this.selectRestaurant.bind(this),

            verifyStripe: this.verifyStripe.bind(this),
            viewStripeDashboard: this.viewStripeDashboard.bind(this),
            syncPrism: this.syncPrism.bind(this),
            addPosMenu: this.addPosMenu.bind(this),
            addToCatList: this.addToCatList.bind(this),
            fetchReservations: this.fetchReservations.bind(this),
            getAvailableTables: this.getAvailableTables.bind(this),

            //kumbaURL : process.env.REACT_APP_BACKEND_URL_INDIA
            kumbaURL: process.env.REACT_APP_BACKEND_URL,
            rupeeSym:
                process.env.REACT_APP_COUNTRY === 'IN'
                    ? '\u20B9'
                    : process.env.REACT_APP_CURRENCY_SYM,
            //loading : false,
            pageName: 'Food Bots',

            // Color Schemes
            primaryColor: '#1d3557',
            primaryTextColor: '#379adb',
            secondaryColor: '#E5F3F6',
            dangerColor: '#bc0d35',
            successColor: '#1dbf96',

            catList: [],
            posMenuList: ['All'],
            activePostMenu: 'All',

            deliveryAddress: null,
            selectedOrderType: null,

            socketConnected : false,

            // selectedServer : null,
        };

        io.socket.on(
            'restaurantupdate',
            function (data) {
                this.loadRestaurantDetails();
            }.bind(this)
        );
    }

    async getPaymentUpdates() {
        try {
            let url = window.kumbaURL + '/restaurant/paymentupdates/';
            let header = { 'foodbots-header': this.state.token };
            const response = await axios({
                url,
                method: 'GET',
                headers: header,
            });
            let data = response.data;
            this.setState({ paymentUpdates: data });
            return Promise.resolve(data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    addToCatList(catItem) {
        if (!this.state.catList.includes(catItem)) {
            this.setState({
                catList: [...this.state.catList, catItem],
            });
        }
    }

    async removePaymentUpdate(orderID) {
        try {
            let url = window.kumbaURL + '/restaurant/paymentupdates/' + orderID;
            let header = { 'foodbots-header': this.state.token };
            const response = await axios({
                url,
                method: 'PUT',
                headers: header,
            });
            // return await this.getPaymentUpdates(); //To initialize socket connection
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    async componentDidMount() {
        if (this.state.restID && this.state.token) {
            await this.loadRestaurantDetails();
            // await this.getPaymentUpdates();
            await this.getAvailableTables();
        }

        if (this.state.selectedRestID) {
            this.selectRestaurant(this.state.selectedRestID);
        } else {
            this.selectRestaurant(this.state.restID);
        }

        io.socket.on(
            'orderconfirm',
            function (data) {
                this.loadRestaurantDetails();
            }.bind(this)
        );
    }

    setLoading() {
        this.setState({ loading: true });
    }

    unsetLoading() {
        this.setState({ loading: false });
    }

    getRestaurantDetails() {
        if (!this.state.io) {
            console.debug('No socket connection.');
        } else if(!this.state.socketConnected){
            //this.setLoading();
            let url = window.kumbaURL + '/restaurant/' + this.state.restID;
            let header = { 'foodbots-header': this.state.token };
            this.state.io.socket.request(
                {
                    url,
                    headers: header,
                    method: 'GET',
                },
                function (jsondata, jwres) {
                    if (jwres.error) {
                        this.setState({ loading: false });
                        console.debug(
                            "Couldn't connect socket. Please try again."
                        );
                        //alert(JSON.stringify(jwres.error));
                        //window.location = "/restaurant";
                    } else {
                        this.setState({socketConnected : true});
                        ////console.log(jsondata);
                        //resolve(jsondata);
                    }
                }.bind(this)
            );
        }
        //console.log('context mounts----', this.state);
    }



    async getAvailableTables() {
        try{
            let url = window.kumbaURL + "/resttables/getalltables/" + this.state.restID
                    let header = {"foodbots-header": this.state.token};
                    const response = await axios({
                      url,
                      headers: header,
                      method: "GET",
                    });
                    var data = response.data
                    console.log(data)
                   this.setState({ tables: data });
                   return Promise.resolve(data);
    
        }catch(err){
            return new Promise((resolve, reject)=>{
                          return reject(err);
                        });
    
        }
    }

    async fetchRestaurantData(restID) {
        try {
            //console.log(restID)
            let url = this.state.kumbaURL + '/restaurant/' + restID;
            //let header={"foodbots-header":this.state.token};
            const response = await axios({
                url,
                method: 'GET',
                //headers : header,
            });
            this.getRestaurantDetails(); //To initialize socket connection
            var restdata = response.data;
            return await this.getCategoryItems(restdata);
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    selectRestaurant(selectedRestID) {
        var selectedRestData = null;
        var newrestID = null;
        //var selectedRestID = null;
        if (selectedRestID === this.state.restID) {
            selectedRestData = this.state.mainrestdata;
            newrestID = this.state.restID;
        } else if (
            this.state.subrestdata &&
            this.state.subrestdata.length > 0
        ) {
            this.state.subrestdata.forEach((eachrest) => {
                if (selectedRestID) {
                    if (eachrest.restdata.restaurant_id === selectedRestID) {
                        selectedRestData = eachrest;
                        newrestID = selectedRestID;
                    }
                } else {
                    selectedRestData = eachrest;
                    newrestID = eachrest.restdata.restaurant_id;
                }
            });
        } else {
            selectedRestData = this.state.mainrestdata;
            newrestID = this.state.restID;
        }

        this.setState({
            selectedRestID: newrestID,
            selectedRestData: selectedRestData,
        });
        sessionStorage.setItem('selectedRestID', newrestID);
    }

    async loadRestaurantDetails() {
        try {
            const data = await this.fetchRestaurantData(this.state.restID);
            this.setNames(data.restdata);
            if (
                data.restdata.sub_restaurants &&
                data.restdata.sub_restaurants.length > 0
            ) {
                const pArray = data.restdata.sub_restaurants.map(
                    async (subrest) => {
                        return await this.fetchRestaurantData(
                            subrest.restaurant_id
                        );
                    }
                );
                const subrestdata = await Promise.all(pArray);

                //subrestdata = subrestdata;
                this.setState({
                    categoryItems: data.categoryItems,
                    categoryIDItems: data.categoryIDItems,
                    mainrestdata: data,
                    restdata: data.restdata,
                    starredItems: data.starredItems,
                    isRestDone: true,
                    categories: data.categories,
                    categoriesList: data.categoriesList,
                    pos_menu_types: data.pos_menu_types,
                    categoryTable: data.categoryTable,
                    menuTable: data.menuTable,
                    categoryChoices: data.categoryChoices,
                    itemChoices: data.itemChoices,
                    itemTable: data.itemTable,
                    subrestdata: subrestdata,
                });
            } else {
                ////console.log(data);
                this.setState({
                    categoryItems: data.categoryItems,
                    categoryIDItems: data.categoryIDItems,
                    mainrestdata: data,
                    restdata: data.restdata,
                    starredItems: data.starredItems,
                    isRestDone: true,
                    categories: data.categories,
                    categoriesList: data.categoriesList,
                    pos_menu_types: data.pos_menu_types,
                    categoryTable: data.categoryTable,
                    menuTable: data.menuTable,
                    categoryChoices: data.categoryChoices,
                    itemChoices: data.itemChoices,
                    itemTable: data.itemTable,
                    subrestdata: null,
                });
            }
            this.selectRestaurant(this.state.selectedRestID || null);
        } catch (err) {
            //console.log(err);
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    setContextState(newState) {
        this.setState(newState);
    }

    addPosMenu(posMenu) {
        //console.log('added ',)
        if (!this.state.posMenuList.includes(posMenu)) {
            const newList = [...this.state.posMenuList];
            newList.push(posMenu);
            this.setState({ posMenuList: newList });
        }
    }

    setNames(restdata) {
        switch (restdata.type) {
            case 'restaurant':
                window.tableTerm = 'Table';
                window.itemTerm = 'Item';
                break;

            case 'drinksprovider':
                window.tableTerm = 'Table';
                window.itemTerm = 'Item';
                break;

            case 'hotel':
                window.tableTerm = 'Room';
                window.itemTerm = 'Service';
                break;

            case 'serviceprovider':
                window.tableTerm = 'Session';
                window.itemTerm = 'Service';
                break;

            case 'itemprovider':
                window.tableTerm = 'Session';
                window.itemTerm = 'Item';
                break;

            default:
                window.tableTerm = 'Table';
                window.itemTerm = 'Item';
                break;
        }
    }

    async getCategoryItems(restdata) {
        return new Promise((resolve, reject) => {
            var categoryItems = {};
            var categoryIDItems = {};
            var starredItems = [];
            var categoriesList = [];
            let categoryTable = {};
            let pos_menu_types = [];
            let menuTable = {};
            //let item_options = [];

            var categoryChoices = [];
            var itemChoices = {};
            let itemTable = {};
            var toggle = {};

            if (restdata.categoryList && restdata.categoryList.length > 0) {
                categoriesList = restdata.categoryList.sort(
                    (a, b) => a.orderNum - b.orderNum
                );
                categoriesList.forEach((cat) => {
                    categoryTable[cat.id] = cat;
                    this.masterCategoryTable[cat.id] = cat;
                });
                categoryChoices = categoriesList.map((category) => {
                    var choice = {
                        value: category.id,
                        label: category.name,
                    };
                    return choice;
                });
            } else {
                categoriesList = [];
                categoryChoices = [];
                categoryTable = {};
            }

            if (
                restdata &&
                restdata.menu_items &&
                restdata.menu_items.length > 0
            ) {
                var menuItems = restdata.menu_items.sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
                let tax_charges = restdata.taxes;

                menuItems.forEach((item) => {
                    var subcat = item.sub_category || 'All';
                    var subcat_id = item.sub_category_id || 'All';

                    menuTable[item.item_id] = item;

                    menuTable[item.item_id].item_tax_details = item.tax_group_id
                        ? tax_charges.filter(
                              (x) => x.tax_group_id === item.tax_group_id
                          )
                        : [];

                    itemTable[item.item_id] = item;

                    if (!itemChoices[item.category_id]) {
                        itemChoices[item.category_id] = [];
                    }

                    if (!categoryItems[item.category]) {
                        categoryItems[item.category] = {};
                    }

                    if (!categoryIDItems[item.category_id]) {
                        categoryIDItems[item.category_id] = {};
                    }

                    if (!categoryItems[item.category][subcat]) {
                        categoryItems[item.category][subcat] = [];
                    }

                    if (!categoryIDItems[item.category_id][subcat_id]) {
                        categoryIDItems[item.category_id][subcat_id] = [];
                    }

                    categoryItems[item.category][subcat].push(item);
                    categoryIDItems[item.category_id][subcat_id].push(item);

                    if (item.isStarred) {
                        starredItems.push(item);
                    }

                    if (
                        item.pos_menutype &&
                        pos_menu_types.indexOf(item.pos_menutype) === -1
                    ) {
                        pos_menu_types.push(item.pos_menutype);
                    }

                    itemChoices[item.category_id].push({
                        value: item.item_id,
                        label: item.name,
                    });
                });

                var preCatList = restdata.categories
                    ? JSON.parse(restdata.categories)
                    : [];
                var currentCatList = categoryItems
                    ? Object.keys(categoryItems).sort()
                    : [];

                var validList = preCatList
                    ? preCatList.filter((x) => currentCatList.indexOf(x) >= 0)
                    : []; //Categories in existing list, missing in current list
                var missingList = currentCatList
                    ? currentCatList.filter((x) => preCatList.indexOf(x) < 0)
                    : []; //Categories in current list, missing from exisiting list

                var categories = validList.concat(missingList);

                var data = {
                    restdata,
                    categoryItems,
                    categoryIDItems,
                    categoriesList,
                    categoryTable,
                    categories,
                    menuTable,
                    starredItems,
                    categoryChoices,
                    itemChoices,
                    itemTable,
                    pos_menu_types,
                };
                return resolve(data);
            } else {
                var data = {
                    restdata,
                    categoryItems,
                    categoryIDItems,
                    categoriesList,
                    categoryTable,
                    categories: [],
                    menuTable,
                    starredItems,
                    categoryChoices,
                    itemChoices,
                    itemTable,
                    pos_menu_types,
                };
                return resolve(data);
            }
        });
    }

    async login(email, password, code) {
        try {
            if (email && password) {
                this.setLoading();
                let url = window.kumbaURL + '/auth/signin';


                const response = await axios({
                    url,
                    method: 'POST',
                    data: {
                        email: email,
                        password: password,
                    },
                });
                var data = response.data;
                this.setState({
                    token: data.token,
                    loading: false,
                    email: email
                });
                sessionStorage.setItem('token', data.token);
                if (data.user.restaurant) {
                    this.setState({
                        restID: data.user.restaurant.restaurant_id,
                    });
                    sessionStorage.setItem(
                        'restID',
                        data.user.restaurant.restaurant_id
                    );
                    await this.loadRestaurantDetails();
                    return Promise.resolve({
                        restID: data.user.restaurant.restaurant_id,
                    });
                } else {
                    return Promise.resolve({ restID: null });
                }
                // if (code) {
                //     var staff = await this.getServer(code);
                //     this.setState({ staff: staff });
                // }

            } else {
                return Promise.reject(new Error("Invalid email/password"));
            }
        } catch (err) {
            //this.setState({signupError : err});
            alert(
                'Something went wrong while logging you in. Please try again.'
            );
            //alert(err);
            return Promise.reject(err);
        }
    }

    async logout() {
        //e.preventDefault();
        this.setLoading();
        try {
            const response = await axios({
                url: window.kumbaURL + '/auth/signout',
                method: 'GET',
            });
            alert('Logged out successfully');
            this.setState({
                restID: null,
                token: null,
                redirect: true,
                restdata: null,
                loading: false,
                isRestDone: false,
                categories: [],
                categoryItems: {},
                staff: null,
            });
            sessionStorage.clear();
        } catch (err) {
            alert("Couldn't log out. Please try again.");
            //alert(err);
        }
    }

    async signup(values) {
        // console.log('sedning --', values);
        try {
            if (values.email && values.password) {
                this.setLoading();
                let url = window.kumbaURL + '/auth/signup';


                var response = await axios({
                    url,
                    method: 'POST',
                    data: {
                        email: values.email,
                        password: values.password,
                    },
                });
                var data = response.data;
                var userdata = data.user;
                // console.log(userdata);
                var token = data.token;
                ////console.log("Image done");
                this.setState({
                    token: token,
                    email: values.email
                });
                // sessionStorage.setItem('token', token);

                return Promise.resolve({ restID: null });
            } else {
                return Promise.reject(new Error("Invalid email or password. Please try again"));
            }

            // if (values.manager && values.code) {
            //     var staff = await this.addServer({
            //         name: values.manager,
            //         access_code: values.code,
            //         role: 'manager',
            //         restaurant: restdata.restaurant_id,
            //     });
            //     this.setState({ staff: staff });
            // }
            //await this.loadRestaurantDetails();
        } catch (err) {
            this.setState({ loading: false });
            alert('Something went wrong. Please try again');
            return Promise.reject(err);
        }
    }

    async signupRestaurant(values) {
        console.log('sedning --', values, 'WITH TOKEN --', this.state.token);
        try {
            let { address,img, logo, ...data } = values;
            let addressdata = address && address.google_address ? {
                name: values.name,
                apt: address.apt || null,
                floor: address.floor || null,
                building: address.building || null,
                zip: address.zip || null,
                note: address.note || null,
                landmark: address.landmark || null,
                google_address: address.google_address.label,
                street: address.google_address.label,
                google_places_id: address.google_address.value.place_id,
            } : null;

            var resp = await axios({
                url: window.kumbaURL + '/restaurant',
                method: 'POST',
                headers: { 'foodbots-header': this.state.token },
                data: {
                    ...data,
                    email: this.state.email,
                    // address: addressdata,
                },
            });

            var restdata = resp.data;
            if(addressdata) {
                await axios({
                    method : 'POST',
                    url:
                        window.kumbaURL +
                        '/address/create',
                    data : {
                        ...addressdata,
                        rest : restdata.restaurant_id
                    },
                    headers: { 'foodbots-header': this.state.token },
                });
            }
            if (values.img) {
                const formData = new FormData();
                formData.append('image', values.img);
                await axios({
                    url:
                        window.kumbaURL +
                        '/image/spaces/restaurant/' +
                        restdata.restaurant_id,
                    method: 'POST',
                    headers: { 'foodbots-header': this.state.token },
                    data: formData,
                });
            }
            if (values.logo) {
                const formData = new FormData();
                formData.append('image', values.logo);
                await axios({
                    url:
                        window.kumbaURL +
                        '/image/spaces/logos/' +
                        restdata.restaurant_id,
                    method: 'POST',
                    headers: { 'foodbots-header': this.state.token },
                    data: formData,
                });
            }
            ////console.log("Image done");
            this.setState({
                restID: restdata.restaurant_id,
                loading: false,
            });
            this.setState({ token: null });
        } catch (err) {
            this.setState({ loading: false });
            alert('Something went wrong. Please try again');
            return Promise.reject(err);
        }
    }

    async updateRestaurant(values) {
        console.log('UPDATING ----', values);
        try {
            let { address,img, logo, ...data } = values;
            let addressdata = address && address.google_address ? {
                name: values.name,
                apt: address.apt || null,
                floor: address.floor || null,
                building: address.building || null,
                zip: address.zip || null,
                note: address.note || null,
                landmark: address.landmark || null,
                google_address: address.google_address.label,
                street: address.google_address.label,
                google_places_id: address.google_address.value.place_id,
            } : null;

            console.log(addressdata);

            let restid = this.state.selectedRestID || this.state.restID;
            // let data = 
            let url = window.kumbaURL + "/restaurant/updaterest/" + restid;
            let header = {
                "foodbots-header": this.state.token,
                "Content-Type": "application/json"
            };
            // delete restData.address;
            console.log('Sending --', values);
            await axios({
                url: url,
                method: 'PUT',
                data: {
                    ...data,
                    // address: addressdata,
                },
                headers: header
            });

            if(addressdata) {
                await axios({
                    method : 'DELETE',
                    url : window.kumbaURL + '/address/remove/' + restid,
                    headers : header
                });
                console.log("ADDRESS : restid" , restid);
                await axios({
                    method : 'POST',
                    url:
                        window.kumbaURL +
                        '/address/create',
                    data : {
                        ...addressdata,
                        rest : restid
                    },
                    headers: { 'foodbots-header': this.state.token },
                });
            }

            if (values.img) {
                const formData = new FormData();
                formData.append('image', values.img);
                await axios({
                    url:
                        window.kumbaURL +
                        '/image/spaces/restaurant/' +
                        this.state.restdata.restaurant_id,
                    method: 'POST',
                    headers: { 'foodbots-header': this.state.token },
                    data: formData,
                });
            }
            if (values.logo) {
                const formData = new FormData();
                formData.append('image', values.logo);
                await axios({
                    url:
                        window.kumbaURL +
                        '/image/spaces/logos/' +
                        this.state.restdata.restaurant_id,
                    method: 'POST',
                    headers: { 'foodbots-header': this.state.token },
                    data: formData,
                });
            }

            await this.state.loadRestaurantDetails();
        } catch (err) {
            console.log(err);
            return Promise.reject(err);
        }
    }

    async fetchReservations(from, to, type) {
        console.log('getting from --', from, 'to --', to, 'for ', type);
        try {
            // this.setState({
            //     [type === 'reservation'
            //         ? 'reservationsLoading'
            //         : 'waitListLoading']: true,
            // });
             this.setState({
                    reservationsLoading: true,
             });
            let url =
                window.kumbaURL +
                `/restaurant/${this.state.restdata.restaurant_id}/${type}/${from}/${to}`;
            let res = await axios(url, {
                headers: { 'foodbots-header': this.state.token },
            });
            // this.setState({
            //     [type === 'reservation'
            //         ? 'reservationsLoading'
            //         : 'waitListLoading']: false,
            //     [type === 'reservation' ? 'reservations' : 'waitList']:
            //         res.data,
            // });
            this.setState({
                reservationsLoading: false,
                reservations: res.data,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            // this.setState({
            //     [type === 'reservation'
            //         ? 'reservationsLoading'
            //         : 'waitListLoading']: false,
            // });
            this.setState({
                reservationsLoading: false,
                reservations : []
            });
            toast.error('Error loading reservations');
            return Promise.reject(err);
        }
    }

    async addServer(serverDetails) {
        if (
            serverDetails.name &&
            serverDetails.role &&
            serverDetails.access_code
        ) {
            try {
                let url = window.kumbaURL + '/servers/';
                let header = { 'foodbots-header': this.state.token };
                const response = await axios({
                    url,
                    headers: header,
                    method: 'POST',
                    data: {
                        name: serverDetails.name,
                        restaurant:
                            serverDetails.restaurant || this.state.restID,
                        server_role: serverDetails.role,
                        access_code: serverDetails.access_code,
                    },
                });

                var data = response.data;

                await this.loadRestaurantDetails();
                return new Promise((resolve, reject) => {
                    return resolve(data);
                });
            } catch (err) {
                //console.log(err);
                return new Promise((resolve, reject) => {
                    return reject(err);
                });
            }
        }
    }

    async getServer(code) {
        try {
            let url = window.kumbaURL + '/servers/code/' + code;
            let header = { 'foodbots-header': this.state.token };
            const response = await axios({
                url,
                headers: header,
                method: 'GET',
            });

            var data = response.data;

            return new Promise((resolve, reject) => {
                return resolve(data);
            });
        } catch (err) {
            //console.log(err);
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    removeItemInOrder(item_id) {
        this.ordersList = this.ordersList.filter(
            (eachorder) => eachorder.item_id !== item_id
        );
        this.setState({ unconfirmedCount: this.ordersList.length });
        this.updateUnconfirmedCount();
    }

    updateItemInOrder(item_id, quantity) {
        this.ordersList.forEach((order) => {
            if (order.item_id === item_id) {
                order.quantity = quantity;
            }
        });
        this.updateUnconfirmedCount();
    }

    addOrders(order) {
        order.subtitle = '';
        if (order.options && order.options.length > 0) {
            order.options.forEach((option) => {
                order.subtitle += option.choicename
                    ? option.choicename + ',' : option.name + ',';
            });
            order.subtitle = order.subtitle
                .trim()
                .replace(/.$/, '.')
                .substr(0, 80);
        }
        order.subtitle += order.instructions;
        this.ordersList.push(order);
        this.updateUnconfirmedCount();
    }

    deleteOrder(order) {
        this.ordersList.splice(order, 1);
        this.updateUnconfirmedCount();
    }

    updateOrder(index, quantity) {
        if (quantity >= 1) {
            this.ordersList[index].quantity = quantity;
        }
        this.updateUnconfirmedCount();
    }

    saveOrder(order, tabledata) {
        this.setState({
            activeOrder: order,
            activeTable: tabledata,
            selectedOrderType : "dine-in"
        });
    }

    async updateUnconfirmedCount() {
        var unconfirmedTotal = 0.0;
        var unconfirmedCount = 0;
        var unconfirmedTax = 0.0;
        var unconfirmedTaxes = 0.0;
        var unconfirmedCGST = 0.0;
        var unconfirmedSGST = 0.0;
        var unconfirmedVAT = 0.0;
        var unconfirmedServiceFee = 0.0;
        var unconfirmedFees = 0.0;
        var unconfirmedGrandTotal = 0.0;

        let unconfirmedTaxDetails = {};

        var orderCredits =
            this.state.orderdata && this.state.orderdata.order_credits
                ? this.state.orderdata.order_credits
                : 0.0;

        for (let index in this.ordersList) {
            // console.log(order);
            let order = this.ordersList[index]
            if (order.options && order.options.length > 0) {
                var subtitle = '';
                order.options.forEach((option) => {
                    subtitle += option.name + ', ';
                });
            }
            order.orderTotal = order.item_total * order.quantity;
            unconfirmedTotal += order.orderTotal;
            unconfirmedCount += order.quantity;

            order.taxTotal = 0.00;
            order.taxInfo = {};

            // console.log('unconfirmedOrderTotal = ' + order.orderTotal);

            if (order.itemdata) {
                let allRests = this.state.subrestdata
                    ? this.state.subrestdata.concat([this.state.mainrestdata])
                    : [this.state.mainrestdata];
                let selectedRest = allRests.filter(
                    (rest) =>
                        rest &&
                        rest.restdata &&
                        rest.restdata.restaurant_id === order.itemdata.menu
                );
                let menuTable =
                    selectedRest && selectedRest.length > 0
                        ? selectedRest[0].menuTable
                        : null;

                if (
                    menuTable &&
                    menuTable[order.itemdata.item_id].item_tax_details
                ) {
                    let item_tax_details =
                        menuTable[order.itemdata.item_id].item_tax_details;

                    let itemTaxes = item_tax_details.filter(
                        (x) => x.tax_on_tax === null
                    );
                    let dependentTaxes = item_tax_details.filter(
                        (x) => x.tax_on_tax !== null
                    );

                    //console.log(itemTaxes, dependentTaxes);

                    let taxDetails = {};

                    for (const index in itemTaxes) {
                        let tax = itemTaxes[index];
                        //console.log(tax);
                        let calcTax = async (tax) => {
                            let tax_amount =
                                Math.round(order.orderTotal * tax.tax_amount) /
                                100;
                            if (!unconfirmedTaxDetails[tax.name]) {
                                unconfirmedTaxDetails[tax.name] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!taxDetails[tax.id]) {
                                taxDetails[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!order.taxInfo[tax.id]) {
                                order.taxInfo[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            taxDetails[tax.id].actual_tax += tax_amount;
                            order.taxInfo[tax.id].actual_tax += tax_amount;
                            order.taxTotal += tax_amount;
                            unconfirmedTaxDetails[tax.name].actual_tax +=
                                tax_amount;
                            return Promise.resolve();
                        };

                        await calcTax(tax);
                        //console.log()
                    }

                    for (const index in dependentTaxes) {
                        let tax = dependentTaxes[index];
                        let calcTax = async (tax) => {
                            let tax_amount = 0.0;
                            if (taxDetails[tax.tax_on_tax]) {
                                tax_amount =
                                    Math.round(
                                        taxDetails[tax.tax_on_tax].actual_tax *
                                            tax.tax_amount
                                    ) / 100;
                            }
                            if (!unconfirmedTaxDetails[tax.name]) {
                                unconfirmedTaxDetails[tax.name] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!taxDetails[tax.id]) {
                                taxDetails[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!order.taxInfo[tax.id]) {
                                order.taxInfo[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            taxDetails[tax.id].actual_tax += tax_amount;
                            order.taxInfo[tax.id].actual_tax += tax_amount;
                            order.taxTotal += tax_amount;
                            unconfirmedTaxDetails[tax.name].actual_tax +=
                                tax_amount;
                            return Promise.resolve();
                        };

                        await calcTax(tax);
                    }
                    //console.log(taxDetails, unconfirmedTaxDetails)
                } else {
                    if (process.env.REACT_APP_COUNTRY === 'IN') {
                        if (!unconfirmedTaxDetails['CGST']) {
                            unconfirmedTaxDetails['CGST'] = {
                                name: 'CGST',
                                description: '',
                                tax_amount: 2.5,
                                actual_tax: 0.0,
                            };
                        }
                        if (!unconfirmedTaxDetails['SGST']) {
                            unconfirmedTaxDetails['SGST'] = {
                                name: 'SGST',
                                description: '',
                                tax_amount: 2.5,
                                actual_tax: 0.0,
                            };
                        }
                        if (!unconfirmedTaxDetails['VAT']) {
                            unconfirmedTaxDetails['VAT'] = {
                                name: 'VAT',
                                description: '',
                                tax_amount: 9,
                                actual_tax: 0.0,
                            };
                        }
                        let service_fee =
                            this.state.restdata &&
                            this.state.restdata.service_fee
                                ? this.state.restdata.service_fee
                                : 0;

                        if (!unconfirmedTaxDetails['SERVICE_FEE']) {
                            unconfirmedTaxDetails['SERVICE_FEE'] = {
                                name: 'SERVICE FEE',
                                description: '',
                                tax_amount: service_fee,
                                actual_tax: 0.0,
                            };
                        }

                        var cgst = order.itemdata.cgst || 0;
                        var sgst = order.itemdata.sgst || 0;
                        var vat = order.itemdata.vat || 0;

                        unconfirmedTaxDetails['CGST'].actual_tax +=
                            (order.orderTotal * cgst) / 100;
                        unconfirmedTaxDetails['SGST'].actual_tax +=
                            (order.orderTotal * sgst) / 100;
                        unconfirmedTaxDetails['VAT'].actual_tax +=
                            (order.orderTotal * vat) / 100;
                        unconfirmedTaxDetails['SERVICE_FEE'].actual_tax +=
                            (order.orderTotal * service_fee * 1.05) / 100;
                    } else {
                        if (!unconfirmedTaxDetails['VAT']) {
                            unconfirmedTaxDetails['VAT'] = {
                                name: 'VAT',
                                description: '',
                                tax_amount: 9,
                                actual_tax: 0.0,
                            };
                        }
                        var vat = order.itemdata.vat || 0;
                        unconfirmedTaxDetails['VAT'].actual_tax +=
                            (order.orderTotal * vat) / 100;
                    }
                }
            } else {
                if (process.env.REACT_APP_COUNTRY === 'IN') {
                    if (!unconfirmedTaxDetails['CGST']) {
                        unconfirmedTaxDetails['CGST'] = {
                            name: 'CGST',
                            description: '',
                            tax_amount: 2.5,
                            actual_tax: 0.0,
                        };
                    }
                    if (!unconfirmedTaxDetails['SGST']) {
                        unconfirmedTaxDetails['SGST'] = {
                            name: 'SGST',
                            description: '',
                            tax_amount: 2.5,
                            actual_tax: 0.0,
                        };
                    }
                    if (!unconfirmedTaxDetails['VAT']) {
                        unconfirmedTaxDetails['VAT'] = {
                            name: 'VAT',
                            description: '',
                            tax_amount: 9,
                            actual_tax: 0.0,
                        };
                    }
                    let service_fee =
                        this.state.restdata && this.state.restdata.service_fee
                            ? this.state.restdata.service_fee
                            : 0;

                    if (!unconfirmedTaxDetails['SERVICE_FEE']) {
                        unconfirmedTaxDetails['SERVICE_FEE'] = {
                            name: 'SERVICE FEE',
                            description: '',
                            tax_amount: service_fee,
                            actual_tax: 0.0,
                        };
                    }
                } else {
                    if (!unconfirmedTaxDetails['VAT']) {
                        unconfirmedTaxDetails['VAT'] = {
                            name: 'VAT',
                            description: '',
                            tax_amount: 9,
                            actual_tax: 0.0,
                        };
                    }
                }
            }
        }

        let kumba_fee =
            this.state.restdata && this.state.restdata.kumbapct
                ? this.state.restdata.kumbapct
                : 0.0;
        let kumba_fixed =
            this.state.restdata && this.state.restdata.kumbafixed
                ? this.state.restdata.kumbafixed
                : 0.0;
        unconfirmedFees =
            (unconfirmedTotal * kumba_fee * 1.18) / 100 + kumba_fixed;
        if (process.env.REACT_APP_COUNTRY === 'IN') {
            if (unconfirmedTaxDetails) {
                //console.log(unconfirmedTaxDetails)
                for (let key in unconfirmedTaxDetails) {
                    // console.log(key);
                    unconfirmedTax += unconfirmedTaxDetails[key].actual_tax;
                }
            }
            //console.log(unconfirmedTax);
            var total = unconfirmedTotal + unconfirmedTax + unconfirmedFees;
            unconfirmedGrandTotal = total;
        } else {
            var total = unconfirmedTotal + unconfirmedTaxes + unconfirmedFees;
            unconfirmedServiceFee =
                (total *
                    (this.state.restdata
                        ? this.state.restdata.service_fee
                        : 0)) /
                100;
            unconfirmedGrandTotal = total + unconfirmedServiceFee;
        }

        // console.log('unconfirmedTax = ' + unconfirmedTax);
        // console.log('unconfirmedGrandTotal = ' + unconfirmedGrandTotal);
        // console.log('orderCredits = ' + orderCredits);

        // console.log(unconfirmedGrandTotal);

        var unconfirmedPayTotal = 0.0;
        var unconfirmedCreditsUsed = 0.0;

        if (orderCredits > unconfirmedGrandTotal) {
            unconfirmedPayTotal = 0.0;
            unconfirmedCreditsUsed = unconfirmedGrandTotal;
        } else {
            unconfirmedPayTotal = parseFloat(
                unconfirmedGrandTotal - orderCredits
            );
            unconfirmedCreditsUsed = orderCredits;
        }

        // console.log('unconfirmedPayTotal = ' + unconfirmedPayTotal);

        this.setState({
            // unconfirmedCount: unconfirmedCount,
            unconfirmedTotal,
            unconfirmedCount,
            unconfirmedTax,
            unconfirmedTaxes,
            unconfirmedCGST,
            unconfirmedSGST,
            unconfirmedVAT,
            unconfirmedServiceFee,
            unconfirmedFees,
            unconfirmedGrandTotal,
            unconfirmedPayTotal,
            unconfirmedTaxDetails,
            unconfirmedCreditsUsed,
            orderCredits,
        });
        return Promise.resolve();
    }

    async submitOrder(userdata) {
        try {
            await this.updateUnconfirmedCount();
            let url = this.state.kumbaURL + '/mainorders/add';
            let header = { 'foodbots-header': this.state.token };
            var orders = [];
            
            // alert("Orders : " + JSON.stringify(orders));
            const _data = {
                order_id: this.state.activeOrder
                    ? this.state.activeOrder.order_id
                    : null,
                order_type: this.state.selectedOrderType,
                server: this.state.selectedStaff
                    ? this.state.selectedStaff.id
                    : null,
                rest_id: this.state.selectedRestID,
                table_id:
                    this.state.selectedOrderType === 'dine-in'
                        ? this.state.activeTable.id
                        : null,
                ordersList: orders,
                name: userdata.name || null,
                email: userdata.email || null,
                phone: this.state.activeOrder
                    ? this.state.activeOrder.user
                    : userdata.phone,
                status: 'accepted',
                credits_used: userdata.credits_used || 0.0,
                num_people: userdata.num_people || null,
                delivery_address: this.state.selectedAddress,
                delivery_charge : this.state.delivery_charge,
                customer_preferred_time : this.state.customer_preferred_time, 
            };
            this.ordersList.forEach((order) => {
                console.log('ORDER --', order);
                // alert(JSON.stringify(order.taxInfo));
                var eachorder = {
                    order_id: this.state.activeOrder
                        ? this.state.activeOrder.order_id
                        : null,
                    item_id: order.item_id,
                    quantity: order.quantity,
                    rest_id: order.restID,
                    table: this.state.selectedOrderType === "dine-in" ? this.state.activeTable.id : null,
                    item_total: order.orderTotal,
                    status : "accepted",
                    // payment_mode: 'kumba',
                    tax_info: JSON.stringify(order.taxInfo),
                    tax_total: order.taxTotal,
                    item_pay_total: order.orderTotal + order.taxTotal,
                    item_specifics: order.instructions,
                    options: order.options.map((option) => option.id),
                    options_json: JSON.stringify(order.options),
                    instructions: order.instructions,
                    us_payment_id: null,
                    ind_payment_id: null,
                };
                orders.push(eachorder);
                console.log('EACH ORDER --', eachorder);
                console.log('_DATA --', _data);
            });
            console.log('======= SENDING ==========', _data)
            const response = await axios({
                url,
                headers: header,
                method: 'POST',
                data: _data
            });
            var data = response.data;

            this.setState({
                activeTable: null,
                selectedOrderType: null,
                deliveryAddress: null,
                staff : null,
            });

            /*Important : To reset all state variables for next order use */
            this.clearOrder();
            return new Promise((resolve, reject) => {
                return resolve(data);
            });
        } catch (err) {
            this.setState({ error: err });
            console.log(err);
            return new Promise((resolve, reject) => {
                return reject(err);
            });
            //alert("Could not add '" + this.props.itemdata.name + "' to cart");
            //alert("Couldn't complete the operation");
        }
    }

    async clearOrder() {
        this.setState({ activeOrder: null, suborder: null, activeTable: null });
        this.ordersList = [];
    }

    /* Service Worker for PushNotifications related code */

    unsubscribePush() {
        navigator.serviceWorker.ready.then(async function (registration) {
            try {
                //Find the registered push subscription in the service worker
                var subscription =
                    await registration.pushManager.getSubscription();
                if (!subscription) {
                    return;
                    //If there isn't a subscription, then there's nothing to do
                } else {
                    await subscription.unsubscribe();
                    await axios.delete(window.kumbaURL + '/push/unregister');
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    async subscribePush(token) {
        try {
            return await axios({
                url: window.kumbaURL + '/push/register',
                method: 'POST',
                data: {
                    token: token,
                    type: 'restaurant',
                    id: this.state.restID,
                    token_type: 'fcm',
                },
            });
        } catch (err) {
            //Do nothing
            console.log(err);
            return Promise.reject(err);
        }
    }

    async registerSubKumba(subscription) {
        try {
            var response = await axios({
                url: window.kumbaURL + '/push/register',
                method: 'POST',
                data: {
                    subscription: subscription,
                    restID: this.state.restID,
                    staff: this.state.staff ? this.state.staff.id : null,
                    role: this.state.staff
                        ? this.state.staff.server_role
                        : null,
                },
            });
            return new Promise((resolve, reject) => {
                return resolve(response.data);
            });
        } catch (e) {
            //console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    async verifyStripe(code) {
        try {
            var response = await axios({
                url: window.kumbaURL + '/stripe/connect/oauth',
                method: 'PUT',
                data: {
                    code: code,
                    restID: this.state.restID,
                },
            });
            return new Promise((resolve, reject) => {
                return resolve(response.data);
            });
        } catch (e) {
            //console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    async viewStripeDashboard() {
        try {
            let header = { 'foodbots-header': this.state.token };
            var response = await axios({
                url:
                    window.kumbaURL +
                    '/stripe/dashboard/' +
                    this.state.restdata.restaurant_id,
                method: 'GET',
                headers: header,
            });
            var linkObj = response.data;
            window.open(linkObj.url);
            return new Promise((resolve, reject) => {
                return resolve(response.data);
            });
        } catch (e) {
            //console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    playSound() {
        document.getElementById('notify_sound').innerHTML = (
            <audio autoplay="autoplay">
                <source src="twirl.mp3" type="audio/mpeg" />
                <source src="twirl.ogg" type="audio/ogg" />
                <embed
                    hidden="true"
                    autostart="true"
                    loop="false"
                    src="twril.mp3"
                ></embed>
            </audio>
        );

        setTimeout(() => {
            document.getElementById('notify_sound').innerHTML = '';
        }, 4000);
    }

    async changeTableDetails(useOrderID, useOldTableID, newTable) {
        try {
            if (useOrderID) {
                let url = window.kumbaURL + '/changetable';
                let header = { 'foodbots-header': this.state.token };
                const response = await axios({
                    url,
                    method: 'PUT',
                    headers: header,
                    data: {
                        old_table: useOldTableID,
                        new_table: newTable,
                        order_id: useOrderID,
                        staff : this.state.selectedStaff ? this.state.selectedStaff.id : null
                    },
                });
                var jsondata = response.data;
                return Promise.resolve(jsondata);
            } else {
                return Promise.resolve(null);
            }
        } catch (err) {
            //console.log(err);
            return Promise.reject(err);
        }
    }

    async syncPrism() {
        try {
            let url = window.kumbaURL + '/prizmatec/sync';
            let header = { 'foodbots-header': this.state.token };
            alert('Please wait for 2-3 minutes for the sync to be completed.');
            const response = await axios({
                url,
                method: 'POST',
                headers: header,
                data: {
                    rest_id: this.state.restID,
                },
            });
            return Promise.resolve(null);
        } catch (err) {
            console.log(err);
            return Promise.resolve(null);
            //alert("Please wait for 2-3 minutes for the sync to be completed.")
        }
    }

    render() {
        return (
            <AppContext.Provider
                value={{ ...this.state, ordersList: this.ordersList, masterCategoryTable : this.masterCategoryTable }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export function withTheme(Component) {
    // ...and returns another component...
    return function ThemedComponent(props) {
        // ... and renders the wrapped component with the context!
        // Notice that we pass through any additional props as well
        return (
            <AppContext.Consumer>
                {(context) => <Component {...props} context={context} />}
            </AppContext.Consumer>
        );
    };
}
