import React, {useState, useEffect} from 'react';
import { Container, Form, Row, Col, Button, Label, FormGroup, ButtonToolbar } from 'reactstrap';
import { FieldGroup } from '../../../helpers/FieldGroup';
import Global from '../../../Global';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const LocationDetails = ({values, goNext, goPrev, add, edit}) => {
    let [google_address, setAddress] = useState(null);
    let [apt, setApt] = useState(null);
    let [landmark, setLandmark] = useState(null);
    let [note, setNote] = useState(null);


    let doNext = () => {
        let values = {
            address : {
                google_address : google_address,
                apt : apt,
                landmark : landmark,
                note : note
            }
        };
        console.log('LOCATION STATE CHANGE ---', values);
        goNext(values);
    }

    return(
        <Container>
            <h5 style={{ textAlign: 'center' }}>
                        {"Step(3 of 4) : LOCATION DETAILS"}
            </h5>
            <Form>
                {add || edit ?
                    <FormGroup style={{ height: '85px' }} className='mb-0'>
                        <Label>Select Address</Label>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                            selectProps={{
                                google_address,
                                onChange: (e) => setAddress(e),
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: window.googleCountriesList,
                                }
                            }}
                            isDisabled={!add && !edit}
                        />
                    </FormGroup> : values.address ? <p>{values.address.street}</p> : <p>No address to display</p>
                }
                {google_address ? (
                    <>
                        <FieldGroup
                            id="apt"
                            name="apt"
                            placeholder="Eg: Baxter Building"
                            type="text"
                            label="Apartment/Suite"
                            value={apt || ''}
                            onChange={(e) => setApt(e.target.value)}
                            readOnly={!add && !edit}
                        />
                        <FieldGroup
                            id="landmark"
                            name="landmark"
                            placeholder="Eg: Domino's Pizza"
                            type="text"
                            label="Landmark"
                            value={landmark || ''}
                            onChange={(e) => setLandmark(e.target.value)}
                            readOnly={!add && !edit}
                        />
                        <FieldGroup
                            id="note"
                            name="note"
                            placeholder="Eg: Domino's Pizza"
                            type="textarea"
                            label="Note"
                            value={note || ''}
                            onChange={(e) => setNote(e.target.value)}
                            readOnly={!add && !edit}
                        />
                    </>
                    ) : null
                }
                <hr />
                {add || edit ?
                <ButtonToolbar>
                    <Button color="danger" onClick={() => goPrev()}>Back</Button>
                    <Button onClick={() => doNext()}>Next</Button>
                </ButtonToolbar> : null}
            </Form>
        </Container>
    )
}

export default LocationDetails;