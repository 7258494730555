import React, { useState, useEffect } from 'react';
import { withTheme } from '../../../contexts/AppContext';
import PlaceDetails from './PlaceDetails';
import SocialDetails from './SocialDetails';
import LocationDetails from './LocationDetails';
import ServiceDetails from './ServiceDetails';
import { ButtonToolbar, Container, Progress, Button } from 'reactstrap';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';

const SignUpDetails = withTheme(({ context, add = true, edit = false, onEdit = null }) => {


    let [step, setStep] = useState(0);
    let [values, setValues] = useState({
        name: context.selectedRestData ? context.selectedRestData.restdata.name : context.restdata ? context.restdata.name : null,
        business_name: context.selectedRestData ? context.selectedRestData.restdata.business_name : null,
        phone: context.selectedRestData ? context.selectedRestData.restdata.phone : null,
        cuisine: context.selectedRestData ? context.selectedRestData.restdata.cuisine : null,
        type: context.selectedRestData ? context.selectedRestData.restdata.type : null,
        about_us: context.selectedRestData ? context.selectedRestData.restdata.about_us : null,
        html_about_us: context.selectedRestData ? context.selectedRestData.restdata.html_about_us : null,
        facebook_url: context.selectedRestData ? context.selectedRestData.restdata.facebook_url : null,
        instagram_url: context.selectedRestData ? context.selectedRestData.restdata.instagram_url : null,
        restaurant_url: context.selectedRestData ? context.selectedRestData.restdata.restaurant_url : null,
        whatsapp_url: context.selectedRestData ? context.selectedRestData.restdata.whatsapp_url : null,
        address: context.selectedRestData ? context.selectedRestData.restdata.address : null,
        allowed_menu_types: context.selectedRestData ? context.selectedRestData.restdata.allowed_menu_types : [],
        qrCodeColor: context.selectedRestData ? context.selectedRestData.restdata.qrCodeColor : null,
        svgColor: context.selectedRestData ? context.selectedRestData.restdata.svgColor : null,
        highlightColor: context.selectedRestData ? context.selectedRestData.restdata.highlightColor : null,
        textColor: context.selectedRestData ? context.selectedRestData.restdata.textColor : null,
        svgTextColor: context.selectedRestData ? context.selectedRestData.restdata.svgTextColor : null,
        image: context.selectedRestData ? context.selectedRestData.restdata.image : null,
        logo_image: context.selectedRestData ? context.selectedRestData.restdata.logo_image : null,
    })

    useEffect(() => {
        setValues({
            // ...values,
            name: context.selectedRestData ? context.selectedRestData.restdata.name : null,
            business_name: context.selectedRestData ? context.selectedRestData.restdata.business_name : null,
            phone: context.selectedRestData ? context.selectedRestData.restdata.phone : null,
            cuisine: context.selectedRestData ? context.selectedRestData.restdata.cuisine : null,
            type: context.selectedRestData ? context.selectedRestData.restdata.type : null,
            about_us: context.selectedRestData ? context.selectedRestData.restdata.about_us : null,
            html_about_us: context.selectedRestData ? context.selectedRestData.restdata.html_about_us : null,
            facebook_url: context.selectedRestData ? context.selectedRestData.restdata.facebook_url : null,
            instagram_url: context.selectedRestData ? context.selectedRestData.restdata.instagram_url : null,
            restaurant_url: context.selectedRestData ? context.selectedRestData.restdata.restaurant_url : null,
            whatsapp_url: context.selectedRestData ? context.selectedRestData.restdata.whatsapp_url : null,
            address: context.selectedRestData ? context.selectedRestData.restdata.address : null,
            allowed_menu_types: context.selectedRestData ? context.selectedRestData.restdata.allowed_menu_types : [],
            qrCodeColor: context.selectedRestData ? context.selectedRestData.restdata.qrCodeColor : null,
            svgColor: context.selectedRestData ? context.selectedRestData.restdata.svgColor : null,
            highlightColor: context.selectedRestData ? context.selectedRestData.restdata.highlightColor : null,
            textColor: context.selectedRestData ? context.selectedRestData.restdata.textColor : null,
            svgTextColor: context.selectedRestData ? context.selectedRestData.restdata.svgTextColor : null,
            image: context.selectedRestData ? context.selectedRestData.restdata.image : null,
            logo_image: context.selectedRestData ? context.selectedRestData.restdata.logo_image : null,
        })

    }, [context.selectedRestData])

    let [goProfile, setGoProfile] = useState(false);
    // let [edit, setEdit] = useState(false);

    useEffect(() => {
        console.log('VALUES ----', context.selectedRestData);
    }, [values])

    let goNext = (newvalues) => {
        // console.log(newvalues);
        if (add || edit) {
            setValues({ ...values, ...newvalues });
        }
        if (step !== 3) {
            setStep(step + 1);
        }
    }

    let finalize = async (newvalues) => {
        try {
            let allvalues = { ...values, ...newvalues };
            console.log('FINISHED ----', allvalues);
            setValues(allvalues);
            let data;
            if (add) {
                data = await context.signupRestaurant(allvalues);
                toast("Signup Successful. Please login again");
                setTimeout(() => {
                    setGoProfile(true);
                }, 2000);
            } else if (edit) {
                data = await context.updateRestaurant(allvalues);
                onEdit();
            }
            return Promise.resolve(data);
        } catch (e) {
            return Promise.resolve(e);
        }
    }

    let goPrev = () => {
        setStep(step - 1);
    }

    if (goProfile) {
        return (<Redirect to="/login" />);
    } else {
        return (
            <Container>
                {add ? <h3>Restaurant Details</h3> : null}
                {add || edit ? <Progress multi>
                    <Progress bar value={step * 25} />
                    <Progress bar animated value={25} />
                </Progress> : null
                }
                {add || edit ?
                    (step === 0 ?
                        <PlaceDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} /> :
                        step === 1 ?
                            <SocialDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} /> :
                            step === 2 ?
                                <LocationDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} /> :
                                step === 3 ?
                                    <ServiceDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} finalize={finalize} />
                                    : null)
                    :
                    <>
                        <PlaceDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} />
                        <SocialDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} />
                        <LocationDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} />
                        <ServiceDetails edit={edit} add={add} values={values} goNext={goNext} goPrev={goPrev} finalize={finalize} />
                    </>
                }
            </Container>
        )
    }
});

export default SignUpDetails;