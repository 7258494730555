import React, { Component, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = ({
    onChange,
    defaultValue,
    disabled
}) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={defaultValue}
            disabled={disabled}
            // onReady={editor => {
            //     // You can store the "editor" and use when it is needed.
            //     setEditor(editor);
            //     console.log('Editor is ready to use!', editor);
            // }}
            id="editor"
            name="editor"
            onChange={(event, editor) => {
                const data = editor.getData();
                const rawText = String(data).replace(/(<([^>]+)>)/gi, "")
                onChange(data, rawText.replace("&nbsp;", " "))
            }}
            config={{
                toolbar: {
                        items: [
                            'heading', '|',
                            'fontfamily', 'fontsize', '|',
                            'alignment', '|',
                            'fontColor', 'fontBackgroundColor', '|',
                            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                            'outdent', 'indent', '|',
                            'bulletedList', 'numberedList', 'todoList', '|',
                            'code', 'codeBlock', '|',
                            'insertTable', '|',
                            'blockQuote', '|',
                            'undo', 'redo'
                        ],
                        shouldNotGroupWhenFull: true
                    },
            }}
            // onBlur={(event, editor) => {
            //     console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //     console.log('Focus.', editor);
            // }}
        />
    );
}

export default Editor;