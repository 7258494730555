import React, { useState } from 'react';
import { toast } from 'react-toastify'
import { Spinner } from 'react-activity'
import _Select from 'react-select'
import { Modal, ModalBody, Row } from 'reactstrap'
import { withTheme } from '../../../contexts/AppContext'
import axios from 'axios'

export default withTheme((props) => {
    const { context, updateList, show, toggle, getAllRestTemplate } = props;

    // console.log('TEMPLATE FORM ----', props);
    let header = { 'foodbots-header': context.token };

    const [_data, set_Date] = useState(props._data)

    const [state, setState] = useState({
        loading: false,
    });

    const [data, setData] = useState({
        name: !_data ? null : _data.name,
        categories: !_data ? [] : _data.categories.map(val => { return { value: val.id, label: val.name } }),
        items: !_data ? [] : _data.items.map(val => { return { value: val.item_id, label: val.name } }),
        rest_id: context.selectedRestData.restdata.restaurant_id
    })

    const handleSubmit = e => {
        e.preventDefault()
        setState({
            ...state,
            loading: true
        })
        // console.log('SENDING ---', {
        //     ...data,
        //     categories: data.categories.map(val => val.value),
        //     items: data.items.map(val => val.value)
        // })
        axios(window.kumbaURL + '/menutemplate/create', {
            method: 'POST',
            headers: header,
            data: {
                ...data,
                categories: data.categories.map(val => val.value),
                items: data.items.map(val => val.value)
            }
        })
            .then(res => {
                setState({
                    ...state,
                    loading: false,
                });
                toast.success('Template Created')
                setData({
                    ...data,
                    name: '',
                    categories: [],
                    items: []
                })
                // console.log('Template Created ---', res)
                updateList(res.data)
            })
            .catch(err => {
                setState({
                    ...state,
                    loading: false
                })
                toast.success('Error, Please try again')
                // console.log('ERR ---', err);
            })
    }

    const updateTemplate = (e) => {
        e.preventDefault();
        setState({
            ...state,
            loading: true
        })
        const url = window.kumbaURL + "/menutemplate/" + _data.template_id;
        // console.log('SENDING ---', {
        //     ...data,
        //     categories: data.categories.map(val => val.value),
        //     items: data.items.map(val => val.value)
        // }, 'to ---', url);
        axios(url, {
            method: 'PUT',
            headers: header,
            data: {
                ...data,
                categories: data.categories.map(val => val.value),
                items: data.items.map(val => val.value)
            }
        })
            .then(res => {
                console.log(res)
                setState({
                    ...state,
                    loading: false
                });
                toast.success('Template Updated')
                getAllRestTemplate()
            })
            .catch(err => {
                // console.log('ERROR ----', err)
                setState({
                    ...state,
                    loading: false
                });
                toast.error('Update Error')
            })

    }

    // console.log('_DATA ----', data);
    return <Modal isOpen={show} style={{ paddingTop: '20vh' }} size='lg'>
        <div className='btn-white rounded p-2 d-flex justify-content-end'>
            <button className='btn btn-danger' onClick={toggle}>Close</button>
        </div>
        <ModalBody className='rounded'>
            <form onSubmit={!_data ? handleSubmit : updateTemplate}>
                <div className="form-group">
                    <label for="example-text-input" className="form-control-label">Name</label>
                    <input value={data.name} disabled={state.loading} name='name' required className="form-control" placeholder='Name' type="text" onChange={e => {
                        setData({
                            ...data,
                            name: e.target.value
                        })
                    }} />
                </div>
                <Row>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label for="example-text-input" className="form-control-label">Categories</label>
                            <_Select
                                disabled={state.loading}
                                label='Categories'
                                options={
                                    props.context.selectedRestData.restdata.categoryList.map(item => {
                                        return { value: item.id, label: item.name }
                                    })
                                }
                                isMulti={true}
                                onChange={e => {
                                    //console.log('cat ', e)
                                    setData({
                                        ...data,
                                        categories: e
                                    });
                                }}
                                value={data.categories}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label for="example-text-input" className="form-control-label">Items</label>
                            <_Select
                                disabled={state.loading}
                                label='Items'
                                options={props.context.selectedRestData.restdata.menu_items.map(item => {
                                    return { value: item.item_id, label: item.name }
                                })}

                                isMulti={true}
                                onChange={e => {
                                    console.log('item -', e);
                                    setData({
                                        ...data,
                                        items: e
                                    })
                                }}
                                value={data.items}
                            />
                        </div>
                    </div>
                </Row>
                <div className='text-center'>
                    <button disabled={state.loading} className='btn btn-default w-50' type='submit'>
                        {state.loading ? <Spinner color='white' /> : _data ? "Update" : "Create"}
                    </button>
                </div>
            </form>
        </ModalBody>
    </Modal>
});
