import React, { useState, useEffect } from 'react';
import { withTheme } from '../../contexts/AppContext';
import Layout from '../../components/Layout/Layout';
import SignUpDetails from '../../dashboardComponents/signUp/details/SignUpDetails';
import { Button } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
// import NotificationsView from '../../views/notifications/Notifications_FCM';
import './stripe.css';

const NewProfile = withTheme(({ context, add = false }) => {

    let [edit, setEdit] = useState(0);
    let [restdata, setRestdata] = useState(null);

    let [accountLink, setAccountLink] = useState(null);
    let [stripeStatus, setStripeStatus] = useState("connected");

    const toggleResturantStatus = async () => {
        toast.info("Loading...")
        try {
        let header = {
            "foodbots-header": context.token,
            "Content-Type": "application/json"
        };
        let res = await axios(window.kumbaURL + `/restaurant/${restdata.isOpen ? 'close' : 'open'}/${restdata.restaurant_id}`, {
            method: 'PUT',
            headers: header
        })
        toast[res.data.isOpen ? "success" : 'error'](`Restaurant has ${res.data.isOpen ? "opened" : "closed"}`);
        await context.loadRestaurantDetails();
        } catch(err) {
                toast.error('Error, Please try again')
        }
    };

    const toggleDeliveryStatus = () => {
        toast.info("Loading...")
        let header = {
            "foodbots-header": context.token,
            "Content-Type": "application/json"
        };
        axios(window.kumbaURL + `/restaurant/${restdata.isDeliveryOpen ? 'closedelivery' : 'opendelivery'}/${restdata.restaurant_id}`, {
            method: 'PUT',
            headers: header
        })
            .then(res => {
                toast[res.data.isDeliveryOpen ? "success" : 'error'](`Delivery has ${res.data.isDeliveryOpen ? "opened" : "closed"}`)
            })
            .catch(err => {
                toast.error('Error, Please try again')
            })
    }

    const settleBills = async () => {
        try {
            var toastID = toast("Settling bills ...", { autoClose: false, closeButton: false });
            let header = {
                "foodbots-header": context.token,
                "Content-Type": "application/json"
            }; 
            let res = await axios({
                method: 'POST',
                headers: header,
                url : window.kumbaURL + '/restaurant/settlebills/' + restdata.restaurant_id
            });
            
            toast.update(toastID, {
                render: 'All Bills Cleared',
                type: toast.TYPE.SUCCESS,
                autoClose: 500,
                closeButton: true
            });
            return Promise.resolve();
            // toast.success();
        } catch (error) {
            // toast.error('');
            toast.update(toastID, {
                render: 'Error, Please try again',
                type: toast.TYPE.ERROR,
                autoClose: 800,
                closeButton: true
            });
            return Promise.reject(error);
        }
    }

    const toggleTakeAwayStatus = () => {
        toast.info("Loading...")
        let header = {
            "foodbots-header": context.token,
            "Content-Type": "application/json"
        };
        axios(window.kumbaURL + `/restaurant/${restdata.isTakeawayOpen ? 'closetakeaway' : 'opentakeaway'}/${restdata.restaurant_id}`, {
            method: 'PUT',
            headers: header
        })
            .then(res => {
                console.log('RES ---', res)
                toast[res.data.isTakeawayOpen ? "success" : 'error'](`Takeaway has ${res.data.isTakeawayOpen ? "opened" : "closed"}`)
            })
            .catch(err => {
                toast.error('Error, Please try again')
            })
    }

    const connectStripe = async () => {
        try {
            let response = await axios({
                method : 'POST',
                url : window.kumbaURL + "/stripe/accountlink",
                headers : { "foodbots-header" : context.token },
                data : {
                    rest_id : context.selectedRestID,
                    return_url : process.env.REACT_APP_DASHBOARD_URL,
                    refresh_url : process.env.REACT_APP_DASHBOARD_URL
                }
            });
            let data = response.data;
            console.log(data);
            setAccountLink(data.accountLink);
            setStripeStatus(data.status);
            return Promise.resolve(data);
        } catch(e) {
            return Promise.reject(e);
        }
    }

    const onClick = async () => {
        try {
            let data = await connectStripe();
            if(stripeStatus === "not-connected") {
                window.open(accountLink.url);
            } else {
                toast("Bank Account already connected");
            }
            return Promise.resolve(data);
        } catch(err) {
            return Promise.reject(err);
        }
    }

    useEffect(() => {
        if (context.selectedRestData) {
            console.log('SELECTED ----', context.selectedRestData.restdata)
            setRestdata(context.selectedRestData.restdata)
        }
    }, [context.selectedRestData])

    useEffect(() => {
        if(process.env.REACT_APP_COUNTRY !== "IN") {
            connectStripe();
        }
    }, [context.selectedRestID])



    return (
        <Layout
            pageName="Profile"
            stickyComponent={
                <>
                    {
                        restdata ? <div className='row justify-content-center'>
                            {restdata.allowed_menu_types.includes("dine-in") ? <button onClick={toggleResturantStatus} className={`btn btn-${restdata.isOpen ? 'danger' : 'success'} btn-sm`}>{restdata.isOpen ? "Close Dine-in" : "Open Dine-in"}</button> : null}
                            {restdata.allowed_menu_types.includes("takeaway") ? <button onClick={toggleTakeAwayStatus} className={`btn btn-${restdata.isTakeawayOpen ? 'danger' : 'success'} btn-sm`}>{restdata.isTakeawayOpen ? "Close Takeaway" : "Open Takeaway"}</button> : null}
                            {restdata.allowed_menu_types.includes("delivery") ? <button onClick={toggleDeliveryStatus} className={`btn btn-${restdata.isDeliveryOpen ? 'danger' : 'success'} btn-sm`}>{restdata.isDeliveryOpen ? "Close Delivery" : "Open Delivery"}</button> : null}
                            {restdata.allowed_menu_types.includes("dine-in") && !restdata.isOpen ? <button onClick={settleBills} className={`btn btn-info btn-sm`}>{"Settle All Bills"}</button> : null}
                            {restdata.pos_type && restdata.pos_type === "prizmatec" ? <button onClick={context.syncPrism} className={`btn btn-info btn-sm`}>{"Sync menu from Prism"}</button> : null}
                        </div> : null
                    }
                </>
            }
        >
            {/* <NotificationsView /> */}
            <div className='mt-5'>
                {!add && !edit ? <Button onClick={() => setEdit(true)}>Edit</Button> : <Button color="danger" onClick={() => setEdit(false)}>Discard</Button>}
                {process.env.REACT_APP_COUNTRY !== "IN" ? 
                    stripeStatus === "not-connected" ? 
                        <a onClick={onClick} class="stripe-connect"><span>Connect with</span></a> 
                        : <Button color="success"><span>Payments Enabled</span></Button> 
                        : null
                }
                <SignUpDetails add={false} edit={edit} onEdit={() => setEdit(false)} />
            </div>
        </Layout>
    )
});

export default NewProfile;