import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import EachPopupServer from "./EachPopupServer";
import { sortDown } from "react-icons-kit/fa/sortDown";
import { lock } from "react-icons-kit/fa/lock";
import { Icon } from "react-icons-kit";
import styled from "styled-components";
import { withTheme } from "../../contexts/AppContext";
import Select from 'react-select';

const Wrapper = styled.div`
  height: 100vh;
  z-index: 100;
`;

const ServersListPopup = ({ context }) => {
  const [dropDown, setDropDown] = useState(true);
  const [accessCode, setAccessCode] = useState(null);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);

  let [servers, setServers] = useState([]);

  const getRestaurants = () => {
     let rests = [];
     let subrests = [];
      if(context.subrestdata && context.subrestdata.length > 0) {
          subrests = context.subrestdata.filter(x => x.restdata && x.restdata.servers && x.restdata.servers.length > 0).map(x => x.restdata);
      }
      if(context.restdata && context.restdata.servers && context.restdata.servers.length > 0) {
          rests = [context.restdata];
      }
      rests = [...rests, ...subrests];
      return rests;
  }

  const showRestaurants = () => {
      setRestaurants(getRestaurants());
  }

  let [restaurants, setRestaurants] = useState(getRestaurants());

//   showRestaurants();

  useEffect(() => {
      showRestaurants();
  }, [context.restdata, context.subrestdata]);

//   useEffect(() => {
//       showRestaurants();
//   }, []);

//   useEffect(() => {
//       let rests = [];
//       if(context.subrestdata && context.subrestdata.length > 0) {
//           rests.concat(context.subrestdata.map(x => x.restdata && x.restdata.servers && x.restdata.servers.length > 0 ? x.restdata : null));
//       }
//       if(context.restdata && context.restdata.servers && context.restdata.servers.length > 0) {
//           rests.concat([context.restdata]);
//       }
//       setRestaurants(rests);
//   }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (accessCode === context.selectedStaff.access_code) {
      context.setContextState({
        staff_is_authorized: true,
      });
      setStep(1);
    } else {
      setError("Invalid Access Code");
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (accessCode && accessCode.length > 4) {
      setError("Access Code Should Be 4 Digits");
    } else {
      setError(false);
    }
  }, [accessCode]);

  useEffect(() => {
      if(context.selectedRestData) {
        setServers(context.selectedRestData.restdata.servers);
      }
  }, [context.selectedRestData]);


  if (context && context.selectedRestData && !context.staff_is_authorized && context.selectedRestData.restdata.show_lock_screen) {
    if (!context.selectedStaff) {
        
      return (
        <Wrapper className="bg-default">
          <Modal isOpen size="lg">
            <div className="p-3 d-flex justify-content-between w-100">
              <h2 className="mb-0">Identify Yourself</h2>
            </div>
            <ModalBody className="p-4 bg-white">

                    {step === 1 ? 
                    <div className="p-2">
                        <FormGroup tag="fieldset" className='row'>
                                            {
    
                restaurants.map(val => (
    
        <div
        onClick={() => {
                        context.selectRestaurant(val.restaurant_id);
                        setStep(2);
                    }}
        className={`card p-2 col-md-2 m-2 text-center pt-3 border-info`}
        >
        <h5 className='m-0'>{val.name}</h5>
    </div>
    )
    
    )
                                            }
                                                </FormGroup>
                    {/* <Select
                    options={context && context.subrestdata ? [{value: context.restdata.restaurant_id, label: context.restdata.name}].concat(context.subrestdata.map((val) => ({ value: val.restdata.restaurant_id, label: val.restdata.name }))) : context.restdata ? [{value: context.restdata.restaurant_id, label: context.restdata.name}] : []}
                    placeholder="Select Restaurant"
                    onChange={e => {
                        context.selectRestaurant(e.value);
                        setStep(2);
                    }}
                    /> */}
                </div>
                : null}
              {step === 2 ?
                Object.keys(window.server_roles).filter(x => ["captain", "manager", "chef", "bartender", "supervisor"]).map((val) => {
                    return (
                    <>
                        {servers.filter(
                        (x) => x.server_role === val
                        ).length > 0 ? (
                        <>
                            <div
                            className="row justify-content-between bg-white p-2"
                            onClick={() => setDropDown(!dropDown)}
                            >
                            <h2 className="mb-0" style={{ alignSelf: "center" }}>
                                {val.toUpperCase()}S
                            </h2>
                            <Icon icon={sortDown} size={30} className="mb-2" />
                            </div>
                            <div isOpen={dropDown}>
                            {servers.sort((a,b) => a.name > b.name ? 1 : -1)
                                .filter((x) => x.server_role === val)
                                .map((val, i) => {
                                return <EachPopupServer key={i} staff={val} />;
                                })}
                            </div>
                        </>
                        ) : null}
                    </>
                    );
                }) : null
              }
            </ModalBody>
            <div className="container"></div>
          </Modal>
        </Wrapper>
      );
    }
    if (context && context.selectedStaff) {
      return (
        <Wrapper className="bg-default">
          <Modal isOpen style={{ paddingTop: "30vh" }}>
            <ModalBody className="p-4 bg-white text-center">
              <Icon icon={lock} size={60} />
              <Form className="mt-4" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>ACCESS CODE</Label>

                  <Input
                    type="password"
                    maxLength={"4"}
                    className="text-center"
                    required
                    autoFocus
                    placeholder="Enter 4 Digit Staff Code"
                    onChange={(e) => {
                      setAccessCode(`${e.target.value}`);
                    }}
                    style={{
                      fontSize: "30px",
                      fontWeight: 800,
                      borderColor: error ? "red" : "gray",
                      color: error ? "#ff8099" : "gray",
                      height: "2em",
                    }}
                  />
                </FormGroup>
                <Button className="btn-default w-50" disabled={!accessCode}>
                  Enter
                </Button>
                <Button
                  className="text-danger mt-4 w-50"
                  type="button"
                  onClick={() => {
                      setStep(1);
                    context.setContextState({
                      selectedStaff: null,
                      staff_is_authorized: false,
                    });
                    
                }}
                >
                  Go Back
                </Button>
                {error ? (
                  <div className={`alert alert-danger mt-3 p-1`}>
                    <small style={{ fontSize: "17px" }}>{error}</small>
                  </div>
                ) : null}
              </Form>
            </ModalBody>
          </Modal>
        </Wrapper>
      );
    }
  } else {
    return null;
  }
};

export default withTheme(ServersListPopup);
