import React from 'react'
import { Spinner } from 'react-activity';

export default function AppLoading() {
    return (
        <div style={{ width: "100%", textAlign: "center", marginTop: '40vh' }}>
            <Spinner />
        </div>
    )
}
