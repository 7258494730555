import React from 'react'
import { useState } from 'react'
import OrderHistoryDetails from './OrderHistoryDetails'

export default function EachOrderHistory({ data, parent }) {
	// console.log('DATA --', data)

    const [showDetails, setShowDetails] = useState(false)

	return (
		<div className="card mb-3">
			{data && showDetails && (
				<OrderHistoryDetails
					data={data}
					onClose={() => setShowDetails(false)}
				/>
			)}
			<div className="card-body " onClick={() => setShowDetails(true)}>
				<EachRow label={'Table Name'} value={data?.table_id?.table_name} />
				<EachRow
					label={'Time'}
					value={
						new Date(data?.updatedAt).toDateString() +
						' - ' +
						new Date(data?.updatedAt).toLocaleTimeString()
					}
				/>
				<EachRow
					label={'Bill Amount'}
					value={window.rupeeSym + data?.order_bill}
				/>
				<EachRow label={'Phone Number'} value={data?.user?.user_id} />
			</div>
		</div>
	)
}

const EachRow = ({ label, value }) => {
	return (
		<div className="d-flex border-bottom justify-content-between pb-2 mb-2 align-items-center">
			<label className="form-control-label mb-0">{label}</label>
			<span>{value}</span>
		</div>
	)
}
