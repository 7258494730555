import React from "react";
import styled from "styled-components";
import { user } from "react-icons-kit/icomoon/user";
import { Icon } from "react-icons-kit";
import { withTheme } from "../../contexts/AppContext";

const Wrapper = styled.article`
  background-color: white;
  padding: 10px;
  h4,
  h5 {
    margin: 0px;
    margin-top: 0px !important;
  }
`;

export default withTheme(({ context, staff }) => {
  return (
    <Wrapper
      className="row border-bottom"
      onClick={() =>
        context.setContextState({
          selectedStaff: staff,
        })
      }
    >
      <Icon icon={user} size={40} />
      <div className="ml-3">
        <h4>{staff.name}</h4>
        <h5 className="text-muted">{staff.email}</h5>
      </div>
    </Wrapper>
  );
});
