const displayDate = (date) => {
    // console.log(date);
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    //let time = dateObj.getTime();

    var hours = dateObj.getHours();
    var min = dateObj.getMinutes();
    var sec = dateObj.getSeconds();

    // console.log(hours, min, dateObj.toLocaleDateString())

    let hour = hours > 12 ? hours - 12 : hours;
    let ampm = hours >= 12 ? 'pm' : 'am';

    month = (month < 10 ? '0' : '') + month;
    day = (day < 10 ? '0' : '') + day;
    hour = (hour < 10 ? '0' : '') + hour;
    min = (min < 10 ? '0' : '') + min;
    sec = (sec < 10 ? '0' : '') + sec;

    let time = hour + ':' + min + ampm;

    return day + '/' + month + ' ' + time;
};

module.exports = {
    displayDate : displayDate
};
