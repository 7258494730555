import axios from "axios";
import React, { useState } from 'react';
import { Modal, ModalBody, ButtonGroup, Button } from 'reactstrap'
import { Spinner } from 'react-activity'
import { toast } from "react-toastify";
import { withTheme } from "../../../contexts/AppContext";
import TemplateForm from "./TemplateForm";


const EachTemplate = withTheme(({ data, context, _state, _setState, getAllRestTemplate }) => {
    let header = { 'foodbots-header': context.token };
    const [state, setState] = useState({
        deleteLoading: false,
        confirmDelete: false,
        deleted: false,
        showEdit: false,
        data
    });
    // console.log('each template ---', data);

    const handleDelete = () => {
        setState({ ...state, deleteLoading: true })
        axios(window.kumbaURL + '/menutemplate/' + data.template_id, {
            headers: header,
            method: 'DELETE'
        })
            .then(res => {
                setState({ ...state, deleteLoading: false, confirmDelete: false });
                toast.success('Template Deleted')
                // console.log('DELETED --', res)
                _setState({ ..._state, data: _state.data.filter(x => x.template_id !== data.template_id) })
            })
            .catch(err => {
                toast.error('Something went wrong please try again')
                setState({ ...state, deleteLoading: false })
                // console.log('ERROR --', err);
            })
    }

    return <div className='card m-2'>
        <Modal isOpen={state.confirmDelete} style={{ paddingTop: '40vh' }}>
            <ModalBody>
                <div className='text-center'>
                    <h3 className='h2'>Are you sure you want to delete?</h3>
                    <ButtonGroup>
                        <Button disabled={state.deleteLoading} className='btn-danger' onClick={handleDelete}>{state.deleteLoading ? <Spinner color='white' /> : 'Delete'}</Button>
                        <Button disabled={state.deleteLoading} className='btn-default' onClick={() => setState({ ...state, confirmDelete: false })}>Cancel</Button>
                    </ButtonGroup>
                </div>
            </ModalBody>
        </Modal>
        {/* <TemplateEdit
            show={state.showEdit}
            data={data}
            handleCancel={() => setState({ ...state, showEdit: false })}
            
        /> */}
        <TemplateForm 
            show={state.showEdit}
            _data={data}
            toggle={() => setState({ ...state, showEdit: false })}
            getAllRestTemplate={getAllRestTemplate}
        />
        <div className='card-body'>
            <div className='d-flex justify-content-between row'>
                <h3>{data.name}</h3>
                <div className='d-flex'>
                    <button className='btn btn-success' onClick={() => setState({ ...state, showEdit: true })}>Edit</button>
                    <button className='btn btn-danger' onClick={() => setState({ ...state, confirmDelete: true })} >Delete</button>
                </div>
            </div>
        </div>
    </div>
});

export default React.memo(EachTemplate);
