import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button, Label, FormGroup, Input, ButtonToolbar } from 'reactstrap';
import { FieldGroup } from '../../../helpers/FieldGroup';
import Editor from "../../../components/Editor/Editor";
import Global from '../../../Global';

const ServiceDetails = ({ values, goPrev, goNext, finalize, add, edit }) => {
    let [service_list, setServiceList] = useState(values.allowed_menu_types || []);

    const [colors, setColors] = useState({
        qrCodeColor: values.qrCodeColor || "#426f76",
        svgColor: values.svgColor || '#7ab1c1',
        highlightColor: values.highlightColor || "#EBF5F2",
        textColor: values.textColor || "#000000",
        svgTextColor: values.svgTextColor || "#ffffff"
    });


    let doNext = () => {
        let values = {
            allowed_menu_types: service_list,
            ...colors
        }
        finalize(values);
    };

    useEffect(() => {
        console.log('COLORS PROPS ---', values)
        setColors({
            qrCodeColor: values.qrCodeColor,
        svgColor: values.svgColor,
        highlightColor: values.highlightColor,
        textColor: values.textColor,
        svgTextColor: values.svgTextColor});
        setServiceList(values.allowed_menu_types || []);
    },[values])

    return (
        <Container>
            <h5 style={{ textAlign: 'center' }}>
                {"Step(4 of 4) : SERVICE DETAILS"}
            </h5>
            <Form>
                <FormGroup row>
                    <Label sm={2}>Service Types</Label>
                    <Col sm={{ size: 10 }}>
                        <FormGroup check row>
                            {Global.menuTypes.map(x =>
                                <Col sm={{ size: 4 }}>
                                    <Label check>
                                        <Input type="checkbox" id="checkbox-dine-in"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setServiceList([...service_list, x.value])
                                                } else {
                                                    setServiceList([...service_list].filter(y => y !== x.value))
                                                }
                                            }}
                                            checked={service_list.includes(x.value)}
                                            disabled={!add && !edit}
                                        />
                                        {' ' + x.label}
                                    </Label>
                                </Col>
                            )}
                        </FormGroup>
                    </Col>
                </FormGroup>

                <hr />

                <FormGroup>
                    <Label sm={2} className='pl-0'>App Colors:</Label><br />

                    <div className='row'>
                        <div className='col-md-3'>
                            <FormGroup>
                                <label>QR Code Color</label> - {' '}
                                <input type="color" disabled={!add && !edit} value={colors.qrCodeColor} onChange={e => {
                                    setColors({ ...colors, qrCodeColor: e.target.value })
                                }} />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup>
                                <label>Button Text Color</label> - {' '}
                                <input type="color" disabled={!add && !edit} value={colors.svgTextColor} onChange={e => {
                                    setColors({ ...colors, svgTextColor: e.target.value })
                                }} />
                            </FormGroup>

                        </div>
                        <div className='col-md-3'>
                            <FormGroup>
                                <label>Theme Color</label> - {' '}
                                <input type="color" disabled={!add && !edit} value={colors.svgColor} onChange={e => {
                                    setColors({ ...colors, svgColor: e.target.value })
                                }} />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup>
                                <label>Item Background Color</label> - {' '}
                                <input type="color" disabled={!add && !edit} value={colors.highlightColor} onChange={e => {
                                    setColors({ ...colors, highlightColor: e.target.value })
                                }} />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup>
                                <label>Item Text Color</label> - {' '}
                                <input type="color" disabled={!add && !edit} value={colors.textColor} onChange={e => {
                                    setColors({ ...colors, textColor: e.target.value })
                                }} />
                            </FormGroup>
                        </div>
                    </div>

                </FormGroup>
                <hr />
                {/* {service_list.includes("delivery") ?
                <Form>
                <FormGroup row>
                    <Label sm={2}>{"Would you like Kumba and our partners to handle delivery logistics?"}</Label>
                    <Col sm={{ size: 10 }}>
                        <FormGroup check row>
                            {Global.menuTypes.map(x => 
                            <Col sm={{ size: 4 }}>
                                <Label check>
                                    <Input type="checkbox" id="checkbox-dine-in" 
                                    onChange={(e) => {
                                        if(e.target.checked) {
                                            setServiceList([...service_list, x.value])
                                        } else {
                                            setServiceList([...service_list].filter(y => y !== x.value))
                                        }
                                    }}/>
                                    {' ' + x.label}
                                </Label>
                            </Col>
                            )}
                        </FormGroup>
                    </Col>
                </FormGroup>
                </Form> : null} */}
                {add || edit ?
                    <ButtonToolbar style={{ textAlign: "center" }}>
                        <Button color="danger" onClick={() => goPrev()}>Back</Button>
                        <Button onClick={() => { doNext(); }}>Finish</Button>
                    </ButtonToolbar> : null}
            </Form>
        </Container>
    )
}

export default ServiceDetails;