import React from 'react'
import Global from '../../../Global';
import { Button, Input } from 'reactstrap';


export default function ImageAndLogo({
    values,
    setImg,
    setLogo,
    isDisabled
}) {

    const [state, setState] = React.useState({
        img: null,
        logo: null,
        imgDisplay: null,
        logoDisplay: null
    });

    React.useEffect(() => {
        setImg(state.img)
        setLogo(state.logo)
    }, [state.img, state.logo])

    console.log('IMAGE --', values)

    return (
        <>
            <label>Images</label>
            <div className='row mt-4'>
                <div className='text-center mr-3'>
                    <img
                    className='rounded-circle'
                        src={state.logoDisplay || values.logo_image  || Global.imagePlaceholder}
                        alt='placeholder'
                        width='120px'
                        height='120px'
                    /><br />
                    {/* <button className='mt-2 btn btn-default btn-sm'>Change Logo</button> */}
                    <Button
                        disabled={isDisabled}
                        className='mt-2'
                        color="default"
                        href="#pablo"
                        size="sm"
                    >
                        <Input
                            type="file"
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                zIndex: 10,
                            }}
                            onChange={(e) => {
                                setLogo(
                                    e.target.files[0]
                                );
                                setState({
                                    ...state,
                                    logoDisplay:
                                        URL.createObjectURL(
                                            e.target
                                                .files[0]
                                        ),
                                });
                            }}
                        />
                        Change Logo
                    </Button>
                </div>
                <div className='text-center'>
                    <img
                        src={state.imgDisplay || values.image ||  Global.imagePlaceholder}
                        alt='placeholder'
                        width='120px'
                        style={{
                            width: '200px',
                            height: '120px',
                            borderRadius: '10px'
                        }}
                    /><br />
                    {/* <button className='mt-2 btn btn-default btn-sm'>Change Image</button> */}
                    <Button
                        disabled={isDisabled}
                        className='mt-2'
                        color="default"
                        href="#pablo"
                        size="sm"
                    >
                        <Input
                            type="file"
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                zIndex: 10,
                            }}
                            onChange={(e) => {
                                setImg(
                                    e.target.files[0]
                                );
                                setState({
                                    ...state,
                                    imgDisplay:
                                        URL.createObjectURL(
                                            e.target
                                                .files[0]
                                        ),
                                });
                            }}
                        />
                        Change Image
                    </Button>
                </div>
            </div>
        </>
    )
}
