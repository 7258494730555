export default {
    restTypes: [
        { value: "restaurant", label: "Food Only" },
        { value: "drinksprovider", label: "Drinks Only" },
        { value: "foodndrinksprovider", label: "Food & Drink" },
        { value: "hotel", label: "Hotel" },
        { value: "serviceprovider", label: "Services" },
        { value: "homechef", label: "Home Chef" },
        { value: "itemprovider", label: "Items" }
    ],
    menuTypes : [
        {value : "dine-in", label : "Walk-in"},
        {value : "takeaway", label : "Takeaway"},
        {value : "delivery", label : "Delivery"}
    ],
    imagePlaceholder: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGcXaN1eANzJpV2p02f3Up6BqJ8I46Zc4BonvdyCvldGnrDLoAZ3E9lHH7ZGFr_-0F0LQ&usqp=CAU'
}